import {
  getSessionStorage,
  setSessionStorage,
} from '@mr-yum/frontend-core/dist/support/env'
import { Button, ModalContainer } from '@mr-yum/frontend-ui'
import { Image } from 'components/Shared/Image'
import { Markdown } from 'components/Shared/Markdown'
import { usePopupNotificationModalQuery } from 'lib/gql'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props {
  venueSlug: string
}

export const PopupNotificationModal = ({ venueSlug }: Props) => {
  const [{ data }] = usePopupNotificationModalQuery({
    variables: { venueSlug },
  })
  const venue = data?.guestVenue
  const [isOpen, setOpen] = useState(false)
  const storageKey = `${venueSlug}.popupNotificationDismissed`

  const onClose = () => {
    setSessionStorage(storageKey, String(new Date().getTime()))
    setOpen(false)
  }

  const intl = useIntl()

  useEffect(() => {
    if (getSessionStorage(storageKey) || !venue?.popupNotificationText) {
      return
    }

    const timer = setTimeout(() => {
      setOpen(true)
    }, 700)

    return () => clearTimeout(timer)
  }, [venue, storageKey])

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <div className="w-[calc(100vw-32px)] max-w-[500px] overflow-hidden rounded bg-surface text-foreground">
        {venue?.popupNotificationImage && (
          <div className="relative h-0 w-full bg-surface-subtle pt-[50%]">
            <div className="absolute bottom-0 left-0 right-0 top-0">
              <Image
                image={venue.popupNotificationImage}
                alt={`${
                  venue.popupNotificationText ??
                  intl.formatMessage({
                    defaultMessage: 'A message from the team',
                    id: 'gjG+Pt',
                  })
                }`}
                layout="fill"
                objectFit="cover"
                sizes="358px"
              />
            </div>
          </div>
        )}

        <div className="px-4 pb-4 pt-6">
          <div className="mb-4 my-heading-md">
            <FormattedMessage
              defaultMessage="A message from the team"
              id="gjG+Pt"
            />
          </div>
          {venue?.popupNotificationText && (
            <div className="mb-8 my-body-lg">
              <Markdown>{venue.popupNotificationText}</Markdown>
            </div>
          )}
          <Button
            type="button"
            variant="primary-alternative"
            size="lg"
            onClick={onClose}
            fullWidth
          >
            <FormattedMessage id="JiY/Js" defaultMessage="Thanks!" />
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}
