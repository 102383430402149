import cn from 'classnames'
import React, { PropsWithChildren } from 'react'

export const FloatingButton = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={cn(
        'js-dialog-offset fixed inset-x-0 bottom-0 z-[1] border-t bg-surface-ui-background px-4 pb-[max(12px,calc(2*env(safe-area-inset-bottom)))] pt-3',
        className,
      )}
    >
      <div className="mx-auto w-full max-w-[450px]">{children}</div>
    </div>
  )
}
