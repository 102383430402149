import {
  ModifierGroupPartsFragment,
  UpsellGroupPartsFragment,
} from 'lib/gql.types'

import { isModifierGroup } from '../guards'

export const getFirstAvailableItemIds = (
  groups: Array<ModifierGroupPartsFragment | UpsellGroupPartsFragment>,
): string[] => {
  return groups.reduce<string[]>((acc, group) => {
    if (!group.hasPreselectedOption) return acc

    const firstAvailable = isModifierGroup(group)
      ? group.modifiers.find((item) => item.available)
      : group.upsells.find((item) => item.available)

    if (firstAvailable) {
      return [...acc, firstAvailable.id]
    } else {
      return acc
    }
  }, [])
}
