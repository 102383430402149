import { CartUpsellInput } from 'lib/gql'

import { SingleOptionGroupMap } from '../types'

export const mapSingleOptionGroupToCartUpsell = (
  optionGroup: SingleOptionGroupMap,
): CartUpsellInput[] => {
  return Object.entries(optionGroup)
    .filter((arr): arr is [string, string] => {
      const [upsellGroupId, value] = arr
      return (
        typeof upsellGroupId === 'string' &&
        typeof value === 'string' &&
        value !== 'false' &&
        !!value
      )
    })
    .map(([upsellGroupId, value]) => {
      return {
        id: value,
        quantity: 1,
        upsellGroupId,
      }
    })
}
