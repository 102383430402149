import { CartBaseInput } from 'lib/gql'

import { Item } from '../types'

export const mapModifiersValueToInput = (
  modifiers: Array<Item>,
): CartBaseInput[] => {
  return (
    modifiers
      .filter((modifier) => modifier.quantity > 0)
      /* react hook form useFieldArray has a strange behaviour with objects
      where it autogenerates its own id property that overrides our
      modifier id (see keyName prop https://react-hook-form.com/api/usefieldarray)
      in order to ensure we have the correct modifier id we needed to store a key property
      on our Item type, this key property is removed when submitting our CartBaseInput payload
      to the backend hence the map below */
      /* TODO: it seems like the react hook form team is going to remove this behaviour soon https://github.com/react-hook-form/react-hook-form/discussions/6962#discussioncomment-1585954
      which means that this code can probably change some time soon, you can update the Item type as well as the
      key config in the useFieldArray, that way the extra .map below can be deleted */
      .map((modifier) => ({
        id: modifier.id,
        quantity: modifier.quantity,
      }))
  )
}
