import { Currency } from 'dinero.js'
import {
  MenuQuery,
  MenuVenueQuery,
  TimeBlocksPartsFragment,
  TimeSlotPartsFragment,
} from 'lib/gql'
import entries from 'lodash/fp/entries'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import isNil from 'lodash/fp/isNil'
import join from 'lodash/fp/join'
import map from 'lodash/fp/map'
import mapValues from 'lodash/fp/mapValues'
import omitBy from 'lodash/fp/omitBy'
import pick from 'lodash/fp/pick'
import repeat from 'lodash/repeat'
import startCase from 'lodash/startCase'
import {
  MenuItem,
  MenuSection,
  Restaurant,
  RestrictedDiet,
  WithContext,
} from 'schema-dts'

const getRestrictedDiet = (tag: string): RestrictedDiet | undefined => {
  if (tag === 'vegan') return 'https://schema.org/VeganDiet'
  else if (tag === 'vegetarian') return 'https://schema.org/VegetarianDiet'
  else if (tag === 'gluten-free') return 'https://schema.org/GlutenFreeDiet'
  else return undefined
}

const getSuitableForDietJSONLD = (
  filterTags: string[] | null | undefined,
): RestrictedDiet[] | undefined => {
  if (!filterTags) {
    return undefined
  }
  const restrictedDiets = filterTags
    .map((tag) => getRestrictedDiet(tag))
    .filter(
      (restrictedDiet) => restrictedDiet !== undefined,
    ) as RestrictedDiet[]
  return restrictedDiets.length > 0 ? restrictedDiets : undefined
}

export const TimeBlocksToJsonLd = (timeBlocks: TimeBlocksPartsFragment) =>
  flow(
    pick(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']),
    mapValues(first),
    omitBy(isNil),
    omitBy({ startTime: undefined, endTime: undefined }),
    entries,
    map(
      ([key, { startTime, endTime }]: [string, TimeSlotPartsFragment]) =>
        `${startCase(key.substring(0, 2))} ${startTime}-${endTime}`,
    ),
  )(timeBlocks)

export const MenuItemToJsonLd = (
  menuItem: MenuQuery['guestMenuCategory']['menuSections'][0]['menuItems'][0],
  venueCurrency: Currency,
): MenuItem => ({
  '@type': 'MenuItem',
  identifier: menuItem.id,
  name: menuItem.name!,
  description: menuItem.descriptionPlain!,
  image: menuItem.image?.originalImageUrl,
  offers: {
    '@type': 'Offer',
    price: (menuItem.priceData.priceInCents! / 100).toFixed(2),
    priceCurrency: venueCurrency,
  },
  suitableForDiet: getSuitableForDietJSONLD(menuItem?.filterTags),
})

export const MenuSectionToJsonLd = (
  menuSection: MenuQuery['guestMenuCategory']['menuSections'][0],
  venueCurrency: Currency,
): MenuSection => ({
  '@type': 'MenuSection',
  identifier: menuSection.id,
  name: menuSection.name,
  hasMenuItem: flow(
    map(
      (
        menuItem: MenuQuery['guestMenuCategory']['menuSections'][0]['menuItems'][0],
      ) => MenuItemToJsonLd(menuItem, venueCurrency),
    ),
  )(menuSection.menuItems),
})

export const VenueToJsonLd = (
  venue: MenuVenueQuery['guestVenue'],
  menuSections: MenuQuery['guestMenuCategory']['menuSections'],
): WithContext<Restaurant> => ({
  '@context': 'https://schema.org',
  '@type': 'Restaurant',
  identifier: venue?.id,
  name: venue?.location?.name || venue?.name || undefined,
  servesCuisine: flow(map('name'), join(', '))(venue?.cuisines || []),
  priceRange: venue?.location?.priceLevel
    ? repeat('$', venue?.location.priceLevel)
    : undefined,
  currenciesAccepted: venue?.currency,
  telephone: venue?.supportPhone || '',
  email: venue?.supportEmail || '',
  address: {
    '@type': 'PostalAddress',
    streetAddress: venue?.location?.streetAddress || undefined,
    addressLocality: venue?.location?.locality || undefined,
    addressRegion: venue?.location?.adminArea1 || undefined,
    postalCode: venue?.location?.postalCode || undefined,
    addressCountry: venue?.location?.countryCode || undefined,
  },
  url: `https://mryum.com/${venue?.slug}`,
  image: venue?.logo?.originalImageUrl,
  paymentAccepted: 'Cash, Credit Card, Apple Pay, Google Pay',
  openingHours: venue?.hours ? TimeBlocksToJsonLd(venue?.hours) : undefined,
  geo: {
    '@type': 'GeoCoordinates',
    latitude: venue?.location?.latitude,
    longitude: venue?.location?.longitude,
  },
  hasMap:
    venue?.location?.url ||
    `https://www.google.com/maps/place/?q=place_id:${venue?.location?.googlePlaceId}`,
  hasMenu: {
    '@type': 'Menu',
    hasMenuSection: menuSections.map((menuSection) =>
      MenuSectionToJsonLd(menuSection, venue?.currency as Currency),
    ),
  },
})
