import { useCallback, useState } from 'react'

export const useDisclosure = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue)

  const setOpen = useCallback(() => setIsOpen(true), [])
  const setClosed = useCallback(() => setIsOpen(false), [])
  const onToggle = useCallback(() => setIsOpen(!!isOpen), [isOpen])

  return {
    isOpen,
    setOpen,
    setClosed,
    onToggle,
  }
}
