import { Text } from 'components/Skeleton/Text'
import times from 'lodash/times'
import React, { memo } from 'react'

import { ItemOptionSectionHeader, ItemOptionWrapper } from './shared'

export const ItemOptionsSkeleton = memo(() => (
  <div data-testid="item-options-skeleton">
    <ItemOptionSectionHeader className="skeleton" />

    <div className="flex=1">
      {times(3, (i) => (
        <ItemOptionWrapper key={i}>
          <Text chars={18} />
        </ItemOptionWrapper>
      ))}
    </div>

    <div className=" min-h-[172px] flex-none md:min-h-[120px] md:flex-1" />
  </div>
))
