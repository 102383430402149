import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { FloatingButton } from 'components/Shared/FloatingButton'
import { routes } from 'lib/routes'
import throttle from 'lodash/throttle'
import React, { useCallback, useEffect, useState } from 'react'

import { BookNowButton } from './BookNowButton'

interface Props {
  bookingLink?: string | null
  isIframe: boolean
  venueSlug: string
}

export const BookNow = ({ bookingLink, isIframe, venueSlug }: Props) => {
  const [showBookNow, setShowBookNow] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollListen = useCallback(
    throttle(
      () => {
        if (window.scrollY > 300) {
          setShowBookNow(true)
        }
      },
      1000,
      {
        trailing: true,
        leading: true,
      },
    ),
    [setShowBookNow],
  )

  useEffect(() => {
    if (!showBookNow) {
      window.addEventListener('scroll', scrollListen)
      return () => {
        window.removeEventListener('scroll', scrollListen)
      }
    }
    return undefined
  }, [scrollListen, showBookNow])

  return (
    <>
      {showBookNow && (
        <FloatingButton className="hidden sm:block">
          {isIframe && (
            <RouteLink route={routes.bookings} pathData={{ venueSlug }}>
              <a className="hover:no-underline">
                <BookNowButton venueSlug={venueSlug} />
              </a>
            </RouteLink>
          )}
          {!isIframe && (
            <a
              href={bookingLink!}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:no-underline"
            >
              <BookNowButton venueSlug={venueSlug} />
            </a>
          )}
        </FloatingButton>
      )}
    </>
  )
}
