import { CenterSpinner } from '@mr-yum/frontend-ui'
import { formatPoints } from 'lib/utils'
import React, { PropsWithChildren } from 'react'

const PointsBalance = (props: PropsWithChildren<{}>) => (
  <h1
    className="text-white my-heading-2xl"
    data-testid="currency-balance"
    {...props}
  />
)
const PointsNoun = (props: PropsWithChildren<{}>) => (
  <p
    className="mb-6 uppercase tracking-widest text-white my-body-sm"
    data-testid="currency-noun"
    {...props}
  />
)

interface Props {
  balance: number | null
  noun: string | null
  isFetching: boolean
}

const PointsDisplay = ({ balance, noun, isFetching }: Props) => {
  if (balance === null && isFetching) return <CenterSpinner className="mt-8" />
  if (balance === null && !isFetching) return null

  return (
    <>
      <PointsBalance>{formatPoints(balance || 0)}</PointsBalance>
      <PointsNoun>{noun}</PointsNoun>
    </>
  )
}

export default PointsDisplay
