import {
  Button,
  LockFilledIcon,
  Logo,
  StarFilledIcon,
} from '@mr-yum/frontend-ui'
import { Sheet } from 'components/Common/Sheet'
import { ResponsiveModal } from 'components/ResponsiveModal'
import { PancakeParlourLogo } from 'components/Rewards/PancakeParlourLogo'
import { RightLeftArrowIcon } from 'components/Rewards/RightLeftArrowIcont'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props {
  onClose(): void
}

export const SignUpSheet = ({ onClose }: Props) => {
  const intl = useIntl()

  const anchorProps = {
    href: 'https://www.pancakeparlour.com.au/loyalty-sign-up',
    target: '_blank',
    rel: 'external noreferrer',
  }

  return (
    <ResponsiveModal onClose={onClose}>
      <Sheet
        showBack={false}
        isCloseable
        onClose={onClose}
        title={
          <FormattedMessage defaultMessage="Sign up to rewards" id="jxMNbz" />
        }
      >
        <div className="p-3 ">
          <div className="m-3 flex items-center justify-center space-x-2 pb-5">
            <div className="flex aspect-square w-[86px] items-center justify-center rounded bg-surface-inverted px-2 text-foreground-inverted">
              <Logo />
            </div>
            <RightLeftArrowIcon />
            <PancakeParlourLogo />
          </div>
          <div>
            <div className="justify-left flex items-center space-x-1 pb-2 ps-4">
              <div className="m-3 flex h-14 w-14 shrink-0 items-center justify-center rounded-full bg-surface-subtle text-foreground">
                <StarFilledIcon />
              </div>

              <div>
                <h5 className="my-heading-xs">
                  <FormattedMessage defaultMessage="Get rewards" id="1PDjmz" />
                </h5>

                <p className="pr-7 my-body-sm">
                  <FormattedMessage
                    defaultMessage="Earn points and redeem offers with Pancake Parlour Rewards."
                    id="2d7MeD"
                  />
                </p>
              </div>
            </div>

            <div className="justify-left flex items-center space-x-1 ps-4">
              <div className="m-3 flex h-14 w-14 shrink-0 items-center justify-center rounded-full bg-surface-subtle text-foreground">
                <LockFilledIcon />
              </div>

              <div>
                <h5 className="my-heading-xs">
                  <FormattedMessage
                    defaultMessage="Secure & safe"
                    id="QFYgMr"
                  />
                </h5>

                <p className="pr-7 my-body-sm">
                  <FormattedMessage
                    defaultMessage="Your data is safe and secure with us. We promise not to share it with anyone."
                    id="vIIQbL"
                  />
                </p>
              </div>
            </div>

            <div className="m-6  pb-4 pl-3 pr-3">
              <p className="text-center my-body-sm">
                <FormattedMessage
                  defaultMessage="By tapping ‘Sign up’ you will be taken to Pancake Parlour Rewards Sign Up."
                  id="B5y48b"
                />
              </p>
            </div>
          </div>
          <div className="m-2 pb-7">
            <Button
              as="a"
              aria-label={intl.formatMessage({
                defaultMessage: 'Sign Up',
                id: '39AHJm',
              })}
              onClick={onClose}
              fullWidth
              {...anchorProps}
            >
              <FormattedMessage defaultMessage="Sign Up" id="39AHJm" />
            </Button>
          </div>
        </div>
      </Sheet>
    </ResponsiveModal>
  )
}
