import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

interface CategoryMenuBarProps {
  currentSectionIndexes: number[]
  setCurrentSectionIndexes: Dispatch<SetStateAction<number[]>>
  currentSectionIndex: number
  setAutoScrollActive: Dispatch<SetStateAction<boolean>>
  autoScrollActive: boolean
}

export const defaultCategoryMenuBarContextValue = {
  currentSectionIndexes: [0],
  setCurrentSectionIndexes: () => [],
  currentSectionIndex: 0,
  setAutoScrollActive: () => false,
  autoScrollActive: false,
}

export const CategoryMenuBarContext = createContext<CategoryMenuBarProps>(
  defaultCategoryMenuBarContextValue,
)

export const CategoryMenuBarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [currentSectionIndexes, setCurrentSectionIndexes] = useState<number[]>([
    0,
  ])
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
  const [autoScrollActive, setAutoScrollActive] = useState(false)

  useEffect(() => {
    const sortedCurrentSectionIndexes = currentSectionIndexes.sort()

    const uniqueCurrentSectionIndexes = sortedCurrentSectionIndexes.filter(
      (sectionIndex, index, arr) => arr.indexOf(sectionIndex) === index,
    )

    setCurrentSectionIndex(uniqueCurrentSectionIndexes[0])
  }, [currentSectionIndexes])

  return (
    <CategoryMenuBarContext.Provider
      value={{
        ...defaultCategoryMenuBarContextValue,
        currentSectionIndexes,
        setCurrentSectionIndexes,
        currentSectionIndex,
        setAutoScrollActive,
        autoScrollActive,
      }}
    >
      {children}
    </CategoryMenuBarContext.Provider>
  )
}
