import { Button } from '@mr-yum/frontend-ui'
import { Sheet } from 'components/Common/Sheet'
import { ResponsiveModal } from 'components/ResponsiveModal'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props {
  onClose(): void
}

export const HowItWorksSheet = ({ onClose }: Props) => {
  const intl = useIntl()

  return (
    <ResponsiveModal onClose={onClose}>
      <Sheet
        showBack={false}
        isCloseable
        onClose={onClose}
        title={<FormattedMessage defaultMessage="How it works" id="HK3ph8" />}
      >
        <div className="p-4">
          <div className="space-y-2 pb-9">
            <h3 className="my-heading-sm">
              <FormattedMessage
                defaultMessage="Earn Silver Dollars"
                id="dLHy3c"
              />
            </h3>

            <p className="my-body-sm">
              <FormattedMessage
                defaultMessage="Rewards members earn 1 Silver Dollar (pancake point) for every $1 spent at The Pancake Parlour. Silver Dollars can be used to purchase selected menu items via the Rewards menu."
                id="zdvwH3"
              />
            </p>
          </div>

          <div className="pb-9">
            <h3 className="mb-2 my-heading-sm">
              <FormattedMessage defaultMessage="Get Rewarded" id="I+FTT1" />
            </h3>

            <p className="my-body-sm">
              <FormattedMessage
                defaultMessage="In addition to earning and paying with Silver Dollars, members are rewarded after every 4th and 10th visit with bonus and mystery offers! See more info via the ‘Rewards’ section of our website"
                id="ein8P9"
              />
            </p>
          </div>

          <Button
            type="submit"
            aria-label={intl.formatMessage({
              defaultMessage: 'How it works confirm',
              id: 'FPXFcp',
            })}
            onClick={onClose}
          >
            <FormattedMessage id="NYTGIb" defaultMessage="Got it" />
          </Button>
        </div>
      </Sheet>
    </ResponsiveModal>
  )
}
