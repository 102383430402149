declare global {
  interface Window {
    dataLayer?: any[]
  }
}

export const ecommerceEvent = (
  action: Gtag.EventNames,
  payload: Gtag.EventParams,
) => {
  if (!window?.dataLayer) {
    return
  }

  window.dataLayer.push({
    ecommerce: null,
  })
  window.dataLayer.push({
    event: action,
    ecommerce: payload,
  })
}
