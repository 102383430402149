import { ArrowLeftIcon, CloseIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import {
  ModifierPartsFragment,
  UpgradePartsFragment,
  UpsellPartsFragment,
} from 'lib/gql.types'
import React, {
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react'

type Option = UpsellPartsFragment | ModifierPartsFragment | UpgradePartsFragment

export const ItemDetailsWrapper = ({
  hasImage,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & { hasImage?: boolean }) => (
  <div
    className={cn(
      'relative flex h-full min-h-screen w-screen max-w-[500px] flex-col bg-surface text-foreground md:h-[500px] md:min-h-auto md:flex-row',
      { 'md:max-w-[500px]': !hasImage, 'md:max-w-[1000px]': hasImage },
    )}
    {...props}
  >
    {children}
  </div>
)

export const ItemDetailsImage = ({
  className,
  children,
}: HTMLAttributes<HTMLDivElement>) => (
  <figure
    className={cn(
      'relative h-0 w-full bg-surface-subtle pt-[calc(10/16*100%)] md:h-full md:w-1/2 md:pt-0',
      className,
    )}
  >
    <div className="absolute inset-0">{children}</div>
  </figure>
)

export const ItemDetailsContent = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="flex flex-1 flex-col justify-start overflow-auto bg-surface-ui-background text-foreground md:max-h-[500px] md:min-h-[calc(500px-88px)] md:justify-between"
    {...props}
  >
    {children}
  </div>
)

export const ItemDetailsContentInner = ({
  children,
}: PropsWithChildren<{}>) => (
  <div className="pb-5 pt-4 sm:pt-5 md:px-10 md:pt-6">{children}</div>
)

export const ItemDetailsName = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="mb-2 capitalize my-heading-lg"
    data-testid="menu-item-name"
    {...props}
  >
    {children}
  </div>
)

export const ItemOptionSectionHeader = ({
  className,
  children = <>&nbsp;</>,
  sticky,
  required,
  max,
  error,
  ...props
}: PropsWithChildren<{
  className?: string
  sticky?: boolean
  required?: boolean
  max?: number
  error?: React.ReactNode
}>) => {
  const notes = []

  if (required) {
    notes.push('Required')
  }

  if (max) {
    notes.push(`Max ${max}`)
  }

  return (
    <div className={cn({ sticky }, 'top-0 z-[1]')}>
      <header
        className={cn(
          'mb-[2px] flex flex-col bg-surface-subtle px-4 py-4 my-heading-xs sm:px-5 md:px-10',
          className,
        )}
        {...props}
      >
        <h4 className="text-left">{children}</h4>

        {notes.length > 0 && (
          <p className="mt-[2px] text-left my-body-sm">{notes.join(', ')}</p>
        )}

        {error}
      </header>
    </div>
  )
}

export const OptionGroupError = ({
  children,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
  <p
    className="mt-2 text-semantic-critical-foreground-bold my-body-md"
    data-testid="option-group-error"
    {...props}
  >
    {children}
  </p>
)

export const ItemQuantityError = ({
  children,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
  <p
    className="mb-2 text-semantic-critical-foreground-bold my-body-md"
    {...props}
  >
    {children}
  </p>
)

export const ItemOptionWrapper = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className="px-4 py-4 sm:px-5 md:px-10" {...props}>
    {children}
  </div>
)

export const optionLabelValues = (
  option: Option,
): {
  displayPrice: string | undefined | null
  originalDisplayPrice: string | undefined | null
  isMembershipPricing: boolean
} => {
  let displayPrice: string | undefined | null
  let originalDisplayPrice: string | undefined | null
  let isMembershipPricing = false

  if ((option as UpsellPartsFragment).menuItem?.priceData) {
    isMembershipPricing = (option as UpsellPartsFragment).menuItem?.priceData
      .isMembershipPricing as boolean

    if (isMembershipPricing) {
      displayPrice = (option as UpsellPartsFragment).menuItem?.priceData
        .displayPrice
      originalDisplayPrice = (option as UpsellPartsFragment).menuItem?.priceData
        .originalDisplayPrice
    } else {
      displayPrice = (option as UpsellPartsFragment).displayPrice
    }
  } else {
    isMembershipPricing = (option as ModifierPartsFragment).priceData
      ?.isMembershipPricing as boolean

    if (isMembershipPricing) {
      displayPrice = (option as ModifierPartsFragment).priceData?.displayPrice
      originalDisplayPrice = (option as ModifierPartsFragment).priceData
        ?.originalDisplayPrice
    } else {
      displayPrice = (option as ModifierPartsFragment).displayPrice
    }
  }
  return {
    displayPrice,
    originalDisplayPrice,
    isMembershipPricing,
  }
}

export const OptionPrice = ({ option }: { option: Option }) => {
  const { displayPrice, originalDisplayPrice, isMembershipPricing } =
    optionLabelValues(option)

  return (
    <>
      {isMembershipPricing && (
        <div className="ml-auto flex">
          <span
            className="mx-1 text-foreground-subtle line-through my-body-sm"
            data-testid="menu-item-original-display-price"
          >
            {originalDisplayPrice}
          </span>
        </div>
      )}
      {displayPrice && <p className="ml-2 my-body-sm">{displayPrice}</p>}
    </>
  )
}

export const OptionName = ({ name }: { name: string; available: boolean }) => {
  return <p className="my-body-sm">{name}</p>
}

export const ItemPageBackdrop = ({
  children,
  onClick,
  ...props
}: PropsWithChildren<{ onClick(): void }>) => {
  const backdropRef = useRef<HTMLDivElement | null>(null)

  const handleBackDropClick = useCallback(
    (event: MouseEvent) => {
      if (event.target === backdropRef.current) onClick()
    },
    [onClick],
  )

  useEffect(() => {
    document.addEventListener('click', handleBackDropClick)
    return () => document.removeEventListener('click', handleBackDropClick)
  }, [handleBackDropClick])

  return (
    <div
      ref={backdropRef}
      className="flex min-h-screen w-full items-center justify-center bg-black/50"
      {...props}
    >
      {children}
    </div>
  )
}

export const CloseButton = ({
  onClick,
  ...propTypes
}: HTMLAttributes<HTMLDivElement> & { onClick(): void }) => (
  <div className="fixed left-4 top-4 z-10 md:absolute" {...propTypes}>
    <button
      className={cn(
        'relative select-none border text-center transition-all duration-300 ease-out active:transition-none',
        `rounded-full bg-surface p-2 text-foreground active:border-transparent`,
      )}
      onClick={onClick}
      data-testid="item-details-close"
    >
      <div className="leading-[0px] md:hidden">
        <ArrowLeftIcon />
      </div>

      <div className="hidden leading-[0px] md:block">
        <CloseIcon />
      </div>
    </button>
  </div>
)
