import React, { SVGAttributes } from 'react'

export const RightLeftArrowIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="57"
      height="6"
      viewBox="0 0 57 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M57 3L52 0.113244L52 5.88675L57 3ZM4 3.5L52.5 3.5L52.5 2.5L4 2.5L4 3.5Z"
        fill="#181819"
      />
      <path
        d="M3.8147e-06 3L5 5.88675V0.113249L3.8147e-06 3ZM53 2.5L4.5 2.5V3.5L53 3.5V2.5Z"
        fill="#181819"
      />
    </svg>
  )
}
