import { Button } from '@mr-yum/frontend-ui'
import { Sheet } from 'components/Common/Sheet'
import { ResponsiveModal } from 'components/ResponsiveModal'
import { useLogger } from 'hooks/useLogger'
import isEqual from 'lodash/isEqual'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  dietaryLabelForLanguageByValue,
  DietaryPref,
  DietaryPrefItems,
} from 'stores/enums/dietaryPreferences'
import { MenuStoreContext } from 'stores/MenuStore'

import { PreferencesMenu } from './PreferencesMenu'

interface PreferencesSheetProps {
  isOpen: boolean
  onClose: () => void
}

export const PreferencesSheet = observer(
  ({ isOpen, onClose }: PreferencesSheetProps) => {
    const { logEvent } = useLogger()
    const [isPreferencesOpen, setIsPreferencesOpen] = useState(isOpen)
    const menu = useContext(MenuStoreContext)
    const [dietaryPrefs, setDietaryPrefs] = useState(menu.dietaryPrefs)

    useEffect(() => {
      setIsPreferencesOpen(isOpen)
    }, [isOpen])

    const toggleDietaryPref = useCallback(
      (pref: DietaryPref) => {
        const i = dietaryPrefs.indexOf(pref)
        const newDietaryPrefs = [...dietaryPrefs]
        if (i === -1) {
          newDietaryPrefs.push(pref)
        } else {
          newDietaryPrefs.splice(i, 1)
        }
        setDietaryPrefs(newDietaryPrefs)
      },
      [dietaryPrefs],
    )

    const handleApply = useCallback(() => {
      setIsPreferencesOpen(false)
      onClose()
      setTimeout(() => {
        menu.setDietaryPrefs(dietaryPrefs)
      }, 250)

      if (!isEqual(menu.dietaryPrefs, dietaryPrefs)) {
        logEvent('Change menu dietary preferences', {
          previousDietaryPrefs: menu.dietaryPrefs,
          newDietaryPrefs: dietaryPrefs,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu, dietaryPrefs, logEvent])

    const handleClose = useCallback(() => {
      setDietaryPrefs(menu.dietaryPrefs)
      setIsPreferencesOpen(false)
      onClose()
    }, [menu.dietaryPrefs, onClose])

    useEffect(() => {
      setDietaryPrefs(menu.dietaryPrefs)
    }, [menu.dietaryPrefs])

    return (
      <ResponsiveModal onClose={handleClose}>
        {isPreferencesOpen && (
          <Sheet
            isCloseable={true}
            showBack={false}
            onClose={handleClose}
            title={<FormattedMessage defaultMessage="Filter by" id="S57QRB" />}
          >
            <div className="space-y-5">
              <PreferencesMenu
                onItemSelect={({ item }) => toggleDietaryPref(item.value)}
                items={DietaryPrefItems.map((value) => ({
                  label: dietaryLabelForLanguageByValue[menu.language][value],
                  value,
                  isSelected: dietaryPrefs.indexOf(value) > -1,
                }))}
              />
              <div className="border-t px-4 py-3">
                <Button type="button" onClick={handleApply} size="lg" fullWidth>
                  <FormattedMessage id="EWw/tK" defaultMessage="Apply" />
                </Button>
              </div>
            </div>
          </Sheet>
        )}
      </ResponsiveModal>
    )
  },
)

PreferencesSheet.displayName = 'PreferencesSheet'
