import {
  ModifierGroupPartsFragment,
  UpgradeGroupPartsFragment,
  UpsellGroupPartsFragment,
} from 'lib/gql.types'

import { Item } from './types'

export const isModifierGroup = (
  optionGroup: any,
): optionGroup is ModifierGroupPartsFragment => {
  const modifiers = optionGroup.modifiers
  return !!modifiers
}

export const isUpsellGroup = (
  optionGroup: any,
): optionGroup is UpsellGroupPartsFragment => {
  const upsells = optionGroup.upsells
  return !!upsells
}

export const isUpgradeGroup = (
  optionGroup: any,
): optionGroup is UpgradeGroupPartsFragment => {
  const upgrades = optionGroup.upgrades
  return !!upgrades
}

export const isItem = (item: any): item is Item => !!item.id
