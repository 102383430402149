import {
  ItemDescription,
  ItemName,
  ItemPriceWrapper,
} from 'components/MenuItem/shared'
import { Text } from 'components/Skeleton/Text'
import React, { memo } from 'react'

import {
  ItemDetailsContent,
  ItemDetailsContentInner,
  ItemDetailsImage,
  ItemDetailsWrapper,
} from './shared'

interface Props {
  hasImage?: boolean
}

export const ItemDetailsSkeleton = memo(({ hasImage = true }: Props) => (
  <ItemDetailsWrapper hasImage={hasImage} data-testid="item-details-skeleton">
    {hasImage && <ItemDetailsImage className="skeleton" />}

    <ItemDetailsContent>
      <ItemDetailsContentInner>
        <div className="px-4 md:p-0">
          <div className="mb-8 flex-1">
            <ItemName>
              <span className="display-sm">
                <Text className="w-2/3" />
              </span>
            </ItemName>

            <ItemDescription>
              <span className="paragraph-lg">
                <Text />
                <Text />
                <Text className="w-5/6" />
              </span>
            </ItemDescription>
          </div>

          <div className="flex items-center justify-between">
            <ItemPriceWrapper className="mr-4">
              <span className="heading-xs">
                <Text chars={2} />
              </span>
            </ItemPriceWrapper>
          </div>
        </div>
      </ItemDetailsContentInner>
    </ItemDetailsContent>
  </ItemDetailsWrapper>
))
