import { ModifiersForMenuItemQuery } from 'lib/gql'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import keyBy from 'lodash/fp/keyBy'
import mapValues from 'lodash/fp/mapValues'

import { SingleOptionGroupMap } from '../types'

/**
 * We need to get preselected values in the form according to their select type
 * @param optionGroup The option group we will iterate over
 * @param optionsKey The key to get the options array
 * @param upgradeId The currently selected upgradeId
 *
 */

export const getUpgradeGroupInitialValues = <
  T extends ModifiersForMenuItemQuery['guestMenuItem']['upgradeGroup'][],
  K extends keyof T[0],
>(
  optionGroup: T,
  optionsKey: K,
  upgradeId?: string | null,
): SingleOptionGroupMap =>
  flow(
    keyBy('id'),
    mapValues((optionGroup: T[0]) => {
      const { hasPreselectedOption } = optionGroup!
      const options = optionGroup![optionsKey] as any
      const selectedOption = find({ id: upgradeId })(options)
      const firstAvailableOption: any = flow(
        filter('available'),
        first,
      )(options as any)

      if (selectedOption) return selectedOption.id
      return hasPreselectedOption && firstAvailableOption
        ? firstAvailableOption.id
        : null
    }),
  )(optionGroup)
