import { Button } from '@mr-yum/frontend-ui'
import { useLogger } from 'hooks/useLogger'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  venueSlug: string
}

export const BookNowButton = (_props: Props) => {
  const { logEvent } = useLogger()
  return (
    <Button
      type="button"
      variant="primary-alternative"
      onClick={() => {
        logEvent('Clicked on Booking')
      }}
      fullWidth
    >
      <FormattedMessage defaultMessage="Make a booking" id="ZIbSua" />
    </Button>
  )
}
