import { ClockFilledIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes'
import { WaitTimeEstimateType } from 'lib/gql'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface FlexibleWaitTimesLabelProps {
  waitTimeInMilliseconds: number
  isItemWaitTime: boolean
  estimate: WaitTimeEstimateType
  category?: string
  typographyClass?: string
}

const generateItemWaitTimeMessage = ({
  waitTimeInMilliseconds,
  estimate,
}: {
  waitTimeInMilliseconds: number
  estimate: WaitTimeEstimateType
}) => {
  if (estimate === WaitTimeEstimateType.ExtendedNoEstimate) {
    return (
      <FormattedMessage defaultMessage="Longer wait on this item" id="ZnzlVG" />
    )
  }

  const waitTimeInMinutes = millisecondsToMinutes(waitTimeInMilliseconds)

  const waitTimeHours = Math.floor(waitTimeInMinutes / 60)
  const waitTimeMinutes = waitTimeInMinutes % 60

  if (waitTimeMinutes === 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour wait on this item"
        id="mfOj9L"
        values={{ waitTimeHours }}
      />
    )
  }

  if (waitTimeHours > 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour {waitTimeMinutes} min wait on this item"
        id="WniXzV"
        values={{ waitTimeHours, waitTimeMinutes }}
      />
    )
  }

  return (
    <FormattedMessage
      defaultMessage="{waitTimeInMinutes} min wait on this item"
      id="3O1pZO"
      values={{ waitTimeInMinutes }}
    />
  )
}

const generateMenuSectionWaitTimeMessage = ({
  waitTimeInMilliseconds,
  estimate,
  category,
}: {
  waitTimeInMilliseconds: number
  estimate: WaitTimeEstimateType
  category: string
}) => {
  const categoryString = category ? ` on ${category.toLowerCase()}` : ''
  if (estimate === WaitTimeEstimateType.ExtendedNoEstimate) {
    return (
      <FormattedMessage
        defaultMessage="Longer wait{category}"
        id="xgDxzK"
        values={{ category: categoryString }}
      />
    )
  }

  const waitTimeInMinutes = millisecondsToMinutes(waitTimeInMilliseconds)

  const waitTimeHours = Math.floor(waitTimeInMinutes / 60)
  const waitTimeMinutes = waitTimeInMinutes % 60

  if (waitTimeMinutes === 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour wait{category}"
        id="HelRpj"
        values={{ waitTimeHours, category: categoryString }}
      />
    )
  }

  if (waitTimeHours > 0) {
    return (
      <FormattedMessage
        defaultMessage="{waitTimeHours} hour {waitTimeMinutes} min wait{category}"
        id="xqQmMk"
        values={{ waitTimeHours, waitTimeMinutes, category: categoryString }}
      />
    )
  }

  return (
    <FormattedMessage
      defaultMessage="{waitTimeInMinutes} min wait{category}"
      id="HR9hgw"
      values={{ waitTimeInMinutes, category: categoryString }}
    />
  )
}

export const FlexibleWaitTimesLabel = ({
  waitTimeInMilliseconds,
  estimate,
  isItemWaitTime,
  category = '',
  typographyClass = 'my-label-sm',
}: FlexibleWaitTimesLabelProps) => {
  const waitTimeMessage = isItemWaitTime
    ? generateItemWaitTimeMessage({ waitTimeInMilliseconds, estimate })
    : generateMenuSectionWaitTimeMessage({
        category,
        estimate,
        waitTimeInMilliseconds,
      })

  return (
    <p
      className={cn(
        'flex items-center justify-center text-foreground',
        typographyClass,
      )}
    >
      <ClockFilledIcon className="mr-1 flex-shrink-0" height={16} width={16} />
      {waitTimeMessage}
    </p>
  )
}
