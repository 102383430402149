import dynamic from 'next/dynamic'
import React from 'react'

import { ItemDetailsSkeleton } from './ItemDetailsSkeleton'
import { ItemOptionsSkeleton } from './ItemOptionsSkeleton'

export const ItemOptions = dynamic(
  async () => (await import('./ItemOptions')).ItemOptions,
  { loading: () => <ItemOptionsSkeleton /> },
)

export const ItemDetails = dynamic(
  async () => (await import('./ItemDetails')).ItemDetails,
  { loading: () => <ItemDetailsSkeleton />, ssr: false },
)
