import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Button } from '@mr-yum/frontend-ui'
import SvgSadBeerBoi from 'components/Icons/SvgSadBeerBoi'
import { OrderingTypeSlug, routes } from 'lib/routes'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
}

export const NoMenuCategory = ({ venueSlug, orderingTypeSlug }: Props) => {
  return (
    <div className="flex flex-col items-center pb-6 text-center">
      <div className="mb-4">
        <SvgSadBeerBoi width="147px" height="120px" />
      </div>

      <h1 className="mb-4">
        <FormattedMessage
          defaultMessage="Oh snap! We can't find that menu category!"
          id="0sZFqX"
        />
      </h1>

      <RouteLink
        route={routes.menuCategories}
        pathData={{ venueSlug, orderingTypeSlug }}
        replace
      >
        <Button as="a">
          <FormattedMessage defaultMessage="Back to menu" id="AgoOEq" />
        </Button>
      </RouteLink>
    </div>
  )
}
