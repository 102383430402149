import { ValidLanguageFilters } from 'components/Lang/utils'

export type DietaryPref =
  | 'dairy-intolerance'
  | 'gluten-intolerance'
  | 'nut-intolerance'
  | 'without-garlic-and-onion'
  | 'vegan'
  | 'vegetarian'
  | 'without-garlic-onion-and-potato'

export type DietaryItem = {
  label: string
  value: DietaryPref
}

/**
 * Available dietary preferences values
 */
export const DietaryPrefItems: DietaryPref[] = [
  'vegan',
  'vegetarian',
  'dairy-intolerance',
  'gluten-intolerance',
  'nut-intolerance',
  'without-garlic-and-onion',
  'without-garlic-onion-and-potato',
]

export const dietaryLabelForLanguageByValue: Record<
  ValidLanguageFilters,
  Record<DietaryPref, string>
> = {
  en: {
    vegetarian: 'Vegetarian',
    vegan: 'Vegan',
    'gluten-intolerance': 'Gluten Intolerance',
    'nut-intolerance': 'Nut Intolerance',
    'without-garlic-and-onion': 'Without Garlic and Onion',
    'dairy-intolerance': 'Dairy Intolerance',
    'without-garlic-onion-and-potato': 'Without Garlic, Onion, and Potato',
  },
  de: {
    vegetarian: 'Vegetarisch',
    vegan: 'Vegan',
    'gluten-intolerance': 'Gluten Intoleranz',
    'nut-intolerance': 'Nuss-Intoleranz',
    'without-garlic-and-onion': 'Ohne Knoblauch und Zwiebel',
    'dairy-intolerance': 'Milchunverträglichkeit',
    'without-garlic-onion-and-potato': 'Ohne Knoblauch, Zwiebel und Kartoffel',
  },
  fr: {
    vegetarian: 'Végétarien',
    vegan: 'Végétalien',
    'gluten-intolerance': 'Intolerance au gluten',
    'nut-intolerance': 'Intolérance aux noix',
    'without-garlic-and-onion': 'Sans Ail et Oignon',
    'dairy-intolerance': 'Intolérance aux produits laitiers',
    'without-garlic-onion-and-potato': 'Sans ail, oignon et pomme de terre',
  },
  el: {
    vegetarian: 'Χορτοφάγος',
    vegan: 'Vegan',
    'gluten-intolerance': 'Δυσανεξία στη γλουτένη',
    'nut-intolerance': 'Δυσανεξία ξηρών καρπών',
    'without-garlic-and-onion': 'Χωρίς σκόρδο και κρεμμύδ',
    'dairy-intolerance': 'Δυσανεξία στα γαλακτοκομικά',
    'without-garlic-onion-and-potato': 'Χωρίς σκόρδο, κρεμμύδι και πατάτα',
  },
  zh: {
    vegetarian: '素',
    vegan: '素食主义者',
    'gluten-intolerance': '麸质不耐症',
    'nut-intolerance': '坚果不耐受',
    'without-garlic-and-onion': '没有大蒜和洋葱',
    'dairy-intolerance': '乳制品不耐受',
    'without-garlic-onion-and-potato': '没有大蒜、洋葱和土豆',
  },
  es: {
    vegetarian: 'Vegetariano',
    vegan: 'Vegano',
    'gluten-intolerance': 'Intolerancia al gluten',
    'nut-intolerance': 'Intolerancia a la nuez',
    'without-garlic-and-onion': 'Sin Ajo y Cebolla',
    'dairy-intolerance': 'Intolerancia a los lácteos',
    'without-garlic-onion-and-potato': 'Sin Ajo, Cebolla y Patata',
  },
  it: {
    vegetarian: 'Vegetariano',
    vegan: 'Vegano',
    'gluten-intolerance': 'Intolleranza al glutine',
    'nut-intolerance': 'Intolleranza alle noci',
    'without-garlic-and-onion': 'Senza Aglio e Cipolla',
    'dairy-intolerance': 'Intolleranza al latte',
    'without-garlic-onion-and-potato': 'Senza Aglio, Cipolla e Patate',
  },
}
