import { Section } from 'components/MenuSection'
import { GuestWaitTime } from 'lib/gql'
import { MenuSectionFragment } from 'lib/gql.types'
import { OrderingTypeSlug } from 'lib/routes'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren } from 'react'

import { MenuSectionWaitTime } from './MenuPage'
import { NoFilterResults } from './NoFilterResults'

interface BaseSectProps {
  venueSlug: string
  menuCategorySlug: string
  venueName: string
  orderingTypeSlug: OrderingTypeSlug
  menuSectionWaitTimes: MenuSectionWaitTime[]
  menuCategoryWaitTime: GuestWaitTime | undefined
}

interface SectionsProps extends BaseSectProps {
  sections: MenuSectionFragment[]
}

const Sections = observer(
  ({
    venueSlug,
    orderingTypeSlug,
    sections,
    menuSectionWaitTimes,
    menuCategoryWaitTime,
    ...props
  }: PropsWithChildren<SectionsProps>) => {
    return (
      <>
        {sections.length === 0 && <NoFilterResults />}
        {sections.map((section, index) => {
          return (
            <Section
              key={section.slug}
              sectionIndex={index}
              section={section}
              venueSlug={venueSlug}
              orderingTypeSlug={orderingTypeSlug}
              menuSectionWaitTime={menuSectionWaitTimes.find(
                (menuSectionWaitTime) => menuSectionWaitTime.id === section.id,
              )}
              menuCategoryWaitTime={menuCategoryWaitTime}
              {...props}
            />
          )
        })}
      </>
    )
  },
)

export default Sections

Sections.displayName = 'Sections'
