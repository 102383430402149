import { createDinero } from '@mr-yum/frontend-core/dist/services/createDinero'
import {
  UpgradeModifierUpsell,
  useFilteredItemModifiers,
} from 'components/MenuItem/hooks/useFilteredItemModifiers'
import {
  menuItemSerializer,
  transformUpsellsForTracking,
} from 'components/Tracking/utils'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useLogger } from 'hooks/useLogger'
import useLogOnMount from 'hooks/useLogOnMount'
import { useAddToCartMutation, useModifiersForMenuItemQuery } from 'lib/gql'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { SubmitHandler } from 'react-hook-form'
import { usePrevious } from 'react-use'
import { ecommerceEvent } from 'utils/gtag'

import { FormValues } from '../types'
import { serializeFormValuesToInput } from '../utils/serializeFormValuesToInput'
import {
  ItemFormControllerContextProps,
  ItemFormControllerProps,
  OnClickUpsell,
  SelectedUpsell,
} from './types'

export const ItemFormControllerContext =
  createContext<ItemFormControllerContextProps | null>(null)

export const useItemFormControllerContext = () => {
  const value = useContext(ItemFormControllerContext)

  if (!value) {
    throw new Error('Item Form Data Loader Context must be provided')
  }
  return value
}

export const ItemFormController = ({
  itemSlug,
  children,
  cart,
  priceLevel,
  menuItem,
  handleClose,
  isAvailable,
  menuItemWaitTime,
  startWithADrink,
  currency,
}: ItemFormControllerProps) => {
  const router = useRouter()
  const { logEvent } = useLogger()
  const { query } = router
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const [upgradeId, setUpgradeId] = useState<string | null>(null)
  const [cachedQuantity, setCachedQuantity] = useState<number>(
    menuItem.minQuantity || 1,
  )
  const [selectedUpsell, setSelectedUpsell] = useState<SelectedUpsell>(null)
  const prevSelectedUpsell = usePrevious(selectedUpsell)

  const [
    { data: modifiersData, fetching: modifiersFetching, error: modifiersError },
  ] = useModifiersForMenuItemQuery({
    variables: {
      venueSlug,
      itemSlug,
      orderingType,
      tableNumber: cart?.tableNumber,
      tableArea: cart?.tableArea,
      orderingWindowStartDate: cart?.orderingWindowStartDate,
      priceLevel,
    },
    pause: !query.itemSlug || !isAvailable,
  })

  const upgradeSlug = modifiersData?.guestMenuItem.upgradeGroup?.upgrades?.find(
    (upgrade) => upgrade.id === upgradeId,
  )?.menuItem.slug

  const [
    {
      data: modifiersUpgradeData,
      error: modifiersUpgradeError,
      fetching: modifiersUpgradeFetching,
    },
  ] = useModifiersForMenuItemQuery({
    variables: {
      venueSlug,
      itemSlug: String(upgradeSlug),
      orderingType,
      tableNumber: cart?.tableNumber,
      tableArea: cart?.tableArea,
      orderingWindowStartDate: cart?.orderingWindowStartDate,
      priceLevel,
    },
    pause: !upgradeSlug || !isAvailable,
  })

  useLogOnMount('View item details', {
    venueSlug,
    venueCurrency: currency,
    id: menuItem?.id,
    name: menuItem?.name,
    price: menuItem?.priceData.priceInCents,
    orderingAvailable: isAvailable,
  })

  const onClickUpsell = useCallback<OnClickUpsell>(
    (event, upsell) => {
      logEvent('Clicked on "upsell with modifiers" item', {
        menuItem: menuItemSerializer(menuItem),
        upsell,
      })

      setSelectedUpsell({
        ...upsell,
        menuItem: {
          ...upsell.menuItem,
          name: upsell.name,
        },
      })
      event.stopPropagation()
    },
    [setSelectedUpsell, logEvent, menuItem],
  )

  const onClose = useCallback(async () => {
    if (selectedUpsell) {
      setSelectedUpsell(null)
    } else {
      return handleClose()
    }
  }, [selectedUpsell, handleClose, setSelectedUpsell])

  useLayoutEffect(() => {
    if (prevSelectedUpsell) {
      setTimeout(() => {
        const $upsellCard = document.querySelector(
          `[data-section-id=upsell-card-${prevSelectedUpsell.id}]`,
        ) as HTMLElement

        if ($upsellCard)
          $upsellCard.scrollIntoView({ behavior: 'auto', block: 'center' })
      }, 50)
    }
  }, [prevSelectedUpsell])

  const upgradeModifierUpsell = useMemo<UpgradeModifierUpsell>(() => {
    return {
      upsellGroups: modifiersData?.guestMenuItem.upsellGroups ?? [],
      upgradeGroup: modifiersData?.guestMenuItem.upgradeGroup ?? null,
      modifierGroups: upgradeId
        ? modifiersUpgradeData?.guestMenuItem.modifierGroups
        : modifiersData?.guestMenuItem.modifierGroups,
    }
  }, [upgradeId, modifiersUpgradeData, modifiersData])

  const menuItemGroups = useFilteredItemModifiers(orderingType, {
    modifierGroups: upgradeModifierUpsell.modifierGroups,
    upsellGroups: upgradeModifierUpsell.upsellGroups,
    upgradeGroup: upgradeModifierUpsell.upgradeGroup,
  })

  const { upsellGroups } = menuItemGroups
  const selectedUpsellGroup = useMemo(() => {
    return selectedUpsell
      ? upsellGroups.find((upsellGroup) =>
          upsellGroup.upsells.find((upsell) => upsell.id === selectedUpsell.id),
        )
      : null
  }, [selectedUpsell, upsellGroups])

  const groupsForUpsell = useFilteredItemModifiers(orderingType, {
    modifierGroups: selectedUpsell?.menuItem.modifierGroups ?? [],
  })

  const groups = useMemo(
    () => ({
      menuItem: menuItemGroups,
      upsellItem: groupsForUpsell,
    }),
    [groupsForUpsell, menuItemGroups],
  )

  const [{ error: addToCartError }, addToCart] = useAddToCartMutation()
  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async (formValues) => {
      const serializedFormValues = serializeFormValuesToInput(
        orderingType,
        venueSlug,
        formValues,
      )

      const { data, error } = await addToCart({
        input: serializedFormValues,
      })

      if (!error) {
        const serializedMenuItem = menuItemSerializer(menuItem)

        const addedItem = data?.addToCart.cartItems.find(
          (cartItem) => cartItem.item?.id === menuItem.id,
        )

        logEvent('Add item to cart', {
          menuItem: serializedMenuItem,
          flexibleWaitTimes: {
            menuItemWaitTime,
          },
          itemQuantity: addedItem?.quantity || 0,
          startWithADrink,
        })

        const upsells = transformUpsellsForTracking(
          upsellGroups,
          serializedFormValues.upsells || [],
        )

        const currency = data?.addToCart.currency ?? 'AUD'

        if (addedItem && serializedMenuItem.name) {
          let addedItemsInCents = addedItem.totalInCents
          const price = createDinero(addedItem.totalInCents, currency)
            .divide(addedItem.quantity)
            .toUnit()
          const items = [
            {
              index: 0,
              price,
              item_id: serializedMenuItem.id,
              item_name: serializedMenuItem.name,
              quantity: addedItem.quantity,
            },
          ]

          upsells.forEach((upsell, index) => {
            logEvent('Add upsell to cart', {
              menuItem: serializedMenuItem,
              upsell,
            })

            const upsellPriceInCents = upsell.price ?? 0
            const upsellPrice = createDinero(
              upsellPriceInCents,
              currency,
            ).toUnit()

            items.push({
              index: index + 1,
              price: upsellPrice,
              item_id: upsell.id,
              item_name: upsell.name,
              quantity: 1,
            })
            addedItemsInCents = addedItemsInCents + upsellPriceInCents
          })

          const value = createDinero(addedItemsInCents, currency).toUnit()

          ecommerceEvent('add_to_cart', {
            currency,
            value,
            items,
          })
        }

        handleClose(data?.addToCart)
      }
    },
    [
      orderingType,
      venueSlug,
      addToCart,
      menuItem,
      logEvent,
      menuItemWaitTime,
      startWithADrink,
      upsellGroups,
      handleClose,
    ],
  )

  const onSelectQuantity = useCallback(
    (quantity: number) => setCachedQuantity(quantity),
    [setCachedQuantity],
  )

  const onSelectUpgrade = useCallback(
    (upgradeId: string | null) => setUpgradeId(upgradeId),
    [setUpgradeId],
  )

  return (
    <ItemFormControllerContext.Provider
      value={{
        groups,
        defaultQuantity: cachedQuantity,
        onSelectQuantity,
        selectedUpsell,
        menuItem,
        fetching: modifiersFetching,
        upgradeFetching: modifiersUpgradeFetching,
        isUpsell: !!selectedUpsell,
        upgradeId,
        priceLevel,
        modifiersError,
        modifiersUpgradeError,
        onSelectUpgrade,
        handleClose: onClose,
        onClickUpsell,
        selectedUpsellGroup,
        addToCartError,
        onSubmit,
      }}
    >
      {children}
    </ItemFormControllerContext.Provider>
  )
}
