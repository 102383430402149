import {
  calculateItemQuantity,
  DEFAULT_MAX_QUANTITY,
  getCombinedItemsTotalQuantity,
  ItemQuantity,
} from 'components/MenuItem/utils'
import React, { createContext, ReactNode } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { FormValues } from '../types'
import { useItemFormControllerContext } from './ItemFormController'

export const QuantityContext = createContext<ItemQuantity>({
  minQuantity: 1,
  maxQuantity: DEFAULT_MAX_QUANTITY,
})

export const QuantityProvider = ({ children }: { children: ReactNode }) => {
  const { selectedUpsell, selectedUpsellGroup, menuItem } =
    useItemFormControllerContext()
  const { control } = useFormContext<FormValues>()
  const selectedUpsellGroupField = useWatch({
    control,
    name: `upsells.${selectedUpsellGroup?.id}`,
  })
  const selectedUpsellFormValue = selectedUpsellGroupField?.find(
    (upsell) => upsell.id === selectedUpsell?.id,
  )
  const totalSelected = getCombinedItemsTotalQuantity(
    selectedUpsellGroupField ?? [],
  )
  const { maxQuantity } = selectedUpsellFormValue
    ? calculateItemQuantity({
        upsellSelection: selectedUpsellFormValue,
        upsellGroup: selectedUpsellGroup,
        totalSelected,
      })
    : {
        maxQuantity: menuItem.maxQuantity || DEFAULT_MAX_QUANTITY,
      }

  return (
    <QuantityContext.Provider
      value={{
        maxQuantity,
        minQuantity: selectedUpsellFormValue ? 1 : menuItem.minQuantity || 1,
      }}
    >
      {children}
    </QuantityContext.Provider>
  )
}
