import * as React from 'react'

const SvgBannerGrid = (
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>,
) => (
  <svg
    width="115"
    height="201"
    viewBox="0 0 115 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.483.96v27.718H115v.99H87.483v27.728H115v.99H87.483v27.72H115v.99H87.483v27.729H115v.99H87.483v27.729H115v.99H87.483v27.72H115v.99H87.49v27.717h-.99v-27.717H58.99v27.717h-.997v-27.717H30.49v27.717h-.99v-27.717H1.99v27.717H1v-28.707h28.5V143.544h28.493v-28.719H86.492V87.097H57.994v-28.71H35.028v-.009H29.5V29.668H1v-.588H.993V.96h.99v27.717h27.51V.96h.99v27.717h27.51V.96h.99v27.717h27.51V.96h.99Zm-28.5 28.708h27.51v27.728h-27.51V29.668Zm.007 28.718h27.503v27.72H58.99v-27.72Zm-28.5-28.718h27.503v27.72H30.49v-27.72Zm28.5 86.147h27.503v27.721H58.99v-27.721Zm0 28.719h27.502v27.72H58.99v-27.72Zm-.997 0v27.72H30.49v-27.72h27.503Z"
      fill="currentColor"
    />
  </svg>
)

const ForwardRef = React.forwardRef(SvgBannerGrid)
export default ForwardRef
