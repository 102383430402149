import {
  ModifierGroupPartsFragment,
  UpsellGroupPartsFragment,
} from 'lib/gql.types'

import { isModifierGroup } from '../guards'
import { Item, ItemGroupMap } from '../types'
import { getFirstAvailableItemIds } from './getFirstAvailableItemIds'

export const getItemGroupInitialValues = (
  groups: Array<ModifierGroupPartsFragment | UpsellGroupPartsFragment>,
  itemSelections: Item[] = [],
) => {
  const firstAvailableItemIds = getFirstAvailableItemIds(groups)

  return groups.reduce<ItemGroupMap>((acc, group) => {
    const items = isModifierGroup(group) ? group.modifiers : group.upsells
    return {
      ...acc,
      [group.id]: items.map<Item>((item) => {
        const itemCurrentSelection = itemSelections.find(
          (i) => i.id === item.id,
        )
        /*
          This works well when upgrades have identical modifiers (as we've been assured they almost always do).
          But if you unselect a modifier and then select an upgrade which doesn't have that modifier then we end up losing
          your selection because the preselected option logic kicks in here.
        */
        const quantity = itemCurrentSelection
          ? itemCurrentSelection.quantity
          : firstAvailableItemIds.includes(item.id)
            ? 1
            : 0
        return {
          id: item.id,
          name: item.name,
          quantity,
          key: item.id,
        }
      }),
    }
  }, {})
}
