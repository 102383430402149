import { Alert, InfoFilledIcon } from '@mr-yum/frontend-ui'
import { useDisclosure } from 'hooks/useDisclosure'
import { useLogger } from 'hooks/useLogger'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { SignUpSheet } from './SignUpSheet'

export const RewardsSignUpBanner = () => {
  const { logEvent } = useLogger()
  const { setOpen, isOpen, setClosed } = useDisclosure(false)

  const handleOnSignUpOpen = useCallback(() => {
    logEvent(`Clicked Rewards Sign Up Banner Component`)
    setOpen()
  }, [logEvent, setOpen])

  const handleOnSignUpClose = useCallback(() => {
    logEvent('Closed Rewards Sign Up Banner Component')
    setClosed()
  }, [logEvent, setClosed])

  return (
    <>
      <button onClick={handleOnSignUpOpen} className="w-full text-left">
        <Alert
          variant="info-subtle"
          title={
            <FormattedMessage defaultMessage="Not a member yet?" id="QusafT" />
          }
          icon={<InfoFilledIcon />}
          fullWidth
        >
          <FormattedMessage
            defaultMessage="Tap this banner and sign up to get access to Pancake Parlour's tasty deals."
            id="advxnP"
          />
        </Alert>
      </button>

      {isOpen && <SignUpSheet onClose={handleOnSignUpClose} />}
    </>
  )
}
