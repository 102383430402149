import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { ArrowLeftIcon, Input, SearchOutlineIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { PreferencesButton } from 'components/Menu/PreferencesButton'
import { PreferencesSheet } from 'components/Menu/PreferencesSheet'
import { useLogger } from 'hooks/useLogger'
import { OrderingTypeSlug, routes } from 'lib/routes'
import { observer } from 'mobx-react-lite'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDebounce } from 'react-use'
import { MenuStoreContext } from 'stores/MenuStore'

interface Props {
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
  venueName?: string
}

export const CategoryHeaderBar = observer(
  ({ venueName, venueSlug, orderingTypeSlug }: Props) => {
    const intl = useIntl()
    const { logEvent } = useLogger()
    const menuStore = useContext(MenuStoreContext)
    const [searchTerm, setSearchTerm] = useState(menuStore.searchQuery)
    const [isSearchVisible, setIsSearchVisible] = useState(
      menuStore.searchQuery ? true : false,
    )
    const searchInputRef = useRef<HTMLInputElement>(null)
    const [isPreferencesSheetOpen, setIsPreferencesSheetOpen] = useState(false)

    const onToggleSearch = useCallback(
      (enabled: boolean) => () => setIsSearchVisible(enabled),
      [setIsSearchVisible],
    )

    const onTogglePreferences = useCallback(
      (enabled: boolean) => () => setIsPreferencesSheetOpen(enabled),
      [setIsPreferencesSheetOpen],
    )

    const onCloseSearch = useCallback(() => {
      setSearchTerm('')
      onToggleSearch(false)()
    }, [onToggleSearch, setSearchTerm])

    const onClearSearch = useCallback(() => {
      setSearchTerm('')
      if (searchInputRef && searchInputRef.current) {
        searchInputRef.current.focus()
      }
    }, [setSearchTerm, searchInputRef])

    useEffect(() => {
      if (isSearchVisible && searchInputRef.current) {
        searchInputRef.current.focus()
      }
    }, [isSearchVisible])

    /**
     * menuStore.searchQuery can be cleared from elsewhere.
     */
    useEffect(
      () => setSearchTerm(menuStore.searchQuery),
      [menuStore.searchQuery],
    )

    useDebounce(
      () => {
        menuStore.setSearchQuery(searchTerm)
      },
      250,
      [searchTerm],
    )

    /*
      So that we only send events after user properly stops typing
    */
    useDebounce(
      () => {
        if (!searchTerm) return

        logEvent('Search field was queried', {
          query: searchTerm,
        })
      },
      1_000,
      [searchTerm],
    )

    return (
      <>
        <div className="js-dialog-offset fixed inset-x-0 top-0 z-10 flex justify-between bg-surface-ui-navbar text-foreground-ui-navbar">
          <RouteLink
            route={routes.menuCategories}
            pathData={{
              venueSlug,
              orderingTypeSlug,
            }}
          >
            <button className="flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-none border-0 bg-transparent py-3">
              <ArrowLeftIcon />
            </button>
          </RouteLink>
          <div
            className={cn(
              'relative inline-flex h-14 grow items-center overflow-hidden pr-2',
              { 'justify-between': venueName },
              { 'justify-end': !venueName },
            )}
          >
            {venueName && (
              <h1 className="mr-3 truncate my-heading-sm">{venueName}</h1>
            )}
            <div className="flex">
              <button
                className="relative -mr-1 flex h-8 shrink-0 items-center justify-between space-x-1 px-2"
                type="button"
                onClick={onToggleSearch(true)}
                aria-label="Toggle search"
              >
                <SearchOutlineIcon />
              </button>

              <PreferencesButton onClick={onTogglePreferences(true)} />
            </div>

            <div
              className={cn(
                'absolute inset-0 flex items-center bg-surface-ui-navbar',
                {
                  hidden: !isSearchVisible,
                },
              )}
            >
              <div className="grow">
                <Input
                  enterKeyHint="search"
                  ref={searchInputRef}
                  name="search"
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Search',
                    id: 'xmcVZ0',
                  })}
                  value={searchTerm}
                  onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                    setSearchTerm(e.currentTarget.value)
                  }
                  clearable
                  onClear={onClearSearch}
                  startEnhancer={
                    <div className="flex items-center justify-center px-[7px] text-foreground">
                      <SearchOutlineIcon />
                    </div>
                  }
                />
              </div>
              <button
                className="shrink-0 px-4 my-body-md"
                type="button"
                onClick={onCloseSearch}
              >
                <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
              </button>
            </div>
          </div>
        </div>
        <PreferencesSheet
          isOpen={isPreferencesSheetOpen}
          onClose={onTogglePreferences(false)}
        />
      </>
    )
  },
)

CategoryHeaderBar.displayName = 'CategoryHeaderBar'
