import { ImageItemSkeleton } from 'components/MenuItem/ImageItemSkeleton'
import { ItemGrid, ItemWrapper } from 'components/MenuItem/shared'
import { Text } from 'components/Skeleton/Text'
import times from 'lodash/times'
import React, { memo } from 'react'

import { MenuSectionTitle } from './shared'

export const SectionSkeleton = memo(() => (
  <>
    <div className="mb-16 md:mb-24">
      <div className="mb-8">
        <MenuSectionTitle>
          <Text className="w-2/3 sm:w-1/3 md:w-1/4" />
        </MenuSectionTitle>
      </div>

      <ItemGrid>
        {times(6, (i) => (
          <ItemWrapper key={i}>
            <ImageItemSkeleton />
          </ItemWrapper>
        ))}
      </ItemGrid>
    </div>
  </>
))
