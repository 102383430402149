import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useCartTableInfoQuery, useTableRuleQuery } from 'lib/gql'

/**
 * This hook simplifies table rule status to a boolean state of available or
 * not.
 *
 * The intent is to simplify API responses to reflect current state of design,
 * which only shows a notification when a table is unavailable.
 *
 * Further confirmed by the quote that:
 *
 * > We won’t show a banner if alcohol is not available. Simply have the items
 * > show not available.
 *
 * Design source: https://www.figma.com/file/4OnswPj904J8BOIArFnA1c/Crew-(Staff-app)?type=design&node-id=1182-24123&t=vYyIFLNvjfVs9PZW-0
 */
export const useTableRule = (): { tableAvailable: boolean } => {
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  const [{ data: cartTableInfo }] = useCartTableInfoQuery({
    variables: {
      orderingType,
      venueSlug,
    },
  })
  const [{ data: tableRule }] = useTableRuleQuery({
    variables: {
      area: cartTableInfo?.getCart?.tableArea,
      number: cartTableInfo?.getCart?.tableNumber || 'no-table-number-provided',
    },
    pause: cartTableInfo?.getCart?.tableNumber === undefined,
  })

  return {
    tableAvailable: tableRule?.tableRule.status !== 'all_off',
  }
}
