import escape from 'lodash/escape'
import React from 'react'

const recursiveEscape = (input: any) => {
  // Null or undefined, just return input
  if (typeof input === 'undefined' || input === null) {
    return input
  }

  let output: any
  let i
  const type = typeof input

  if (input instanceof Array) {
    output = []
    for (i = 0; i < input.length; i++) {
      output[i] = recursiveEscape(input[i])
    }
  } else if (type === 'object') {
    output = {}
    for (i in input) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      output[i] = recursiveEscape(input[i])
    }
  } else if (type === 'string') {
    output = escape(input)
  } else if (type === 'number' || type === 'boolean') {
    output = input
  } else {
    output = input
  }

  return output
}

export const JsonLd = ({ data }: { data: object }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(recursiveEscape(data)) }}
  />
)
