import { DEFAULT_MAX_QUANTITY } from 'components/MenuItem/utils'
import { MenuItemSelectRequirement, MenuItemSelectType } from 'lib/gql'
import flow from 'lodash/fp/flow'
import keyBy from 'lodash/fp/keyBy'
import mapValues from 'lodash/fp/mapValues'
import pluralize from 'pluralize'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

import { OptionGroup } from '../OptionGroup'
import { getItemSchema } from '../schemas'

export const getOptionGroupSchema = (
  optionGroup: OptionGroup,
  intl: IntlShape,
  optionName: 'modifiers' | 'upsells' | 'upgrades',
) =>
  Yup.object(
    flow(
      keyBy('id'),
      mapValues(
        ({
          selectRequirement,
          selectType,
          minQuantity,
          maxQuantity,
        }: OptionGroup[0]) => {
          if (optionName === 'modifiers' || optionName === 'upsells') {
            return getItemSchema(
              minQuantity,
              selectRequirement,
              maxQuantity || DEFAULT_MAX_QUANTITY,
              selectType,
              intl,
            )
          }
          switch (selectType) {
            case MenuItemSelectType.Single:
              if (selectRequirement === MenuItemSelectRequirement.Required) {
                return Yup.string()
                  .required('Please make a selection.')
                  .ensure()
              }
              return Yup.string().ensure()

            case MenuItemSelectType.Multi:
              if (selectRequirement === MenuItemSelectRequirement.Required) {
                return Yup.array(Yup.string())
                  .min(minQuantity || 0, () =>
                    intl.formatMessage(
                      {
                        defaultMessage:
                          'Please select at least {minQuantity} {minQuantity, plural, one {item} other{items}}.',
                        id: 'NzzLAJ',
                      },
                      { minQuantity: minQuantity },
                    ),
                  )
                  .max(maxQuantity || DEFAULT_MAX_QUANTITY, () =>
                    intl.formatMessage(
                      {
                        defaultMessage:
                          'Please select only {maxQuantity} {maxQuantity, plural, one {item} other{items}}.',
                        id: 'SQbYcB',
                      },
                      { maxQuantity: maxQuantity || DEFAULT_MAX_QUANTITY },
                    ),
                  )
                  .required(
                    intl.formatMessage({
                      defaultMessage: 'Please make a selection.',
                      id: 'f+6tXq',
                    }),
                  )
                  .compact()
                  .ensure()
              }

              return Yup.array(Yup.string())
                .test({
                  name: 'min',
                  test: (value: any) => {
                    const minReq = minQuantity || 0
                    const arr = value as string[]
                    return arr.length === 0 || arr.length >= minReq
                  },
                  message: `Please select at least ${
                    minQuantity || 0
                  } ${pluralize('item', minQuantity || 0)}.`,
                  exclusive: false,
                })
                .max(
                  maxQuantity || DEFAULT_MAX_QUANTITY,
                  () =>
                    `Please select at most ${maxQuantity} ${pluralize(
                      'item',
                      maxQuantity || DEFAULT_MAX_QUANTITY,
                    )}.`,
                )
                .compact()
                .ensure()
            default:
              return
          }
        },
      ),
    )(optionGroup),
  )
