import { FilterOutlineIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { MenuStoreContext } from 'stores/MenuStore'
import { useIsMounted } from 'usehooks-ts'

interface PreferencesButtonProps {
  onClick: () => void
}

export const PreferencesButton = observer(
  ({ onClick }: PreferencesButtonProps) => {
    const isMounted = useIsMounted()
    const menuStore = useContext(MenuStoreContext)

    return (
      <button
        className={cn(
          'flex h-8 shrink-0 items-center justify-between space-x-1 px-2',
          {
            'ml-2 rounded-full bg-interactive-accent-surface-active text-foreground-inverted':
              isMounted() && menuStore.hasDietaryPrefs,
          },
        )}
        type="button"
        onClick={onClick}
        aria-label="Dietary preferences"
      >
        <FilterOutlineIcon />
        {isMounted() && menuStore.hasDietaryPrefs && (
          <span className="interactivefore block h-4 w-4 rounded-full bg-[theme(textColor.foreground-inverted)] text-center text-[theme(backgroundColor.interactive-accent-surface-active)] my-label-xs">
            {menuStore.dietaryPrefs.length}
          </span>
        )}
      </button>
    )
  },
)

PreferencesButton.displayName = 'PreferencesButton'
