import { Alert, ErrorFilledIcon } from '@mr-yum/frontend-ui'
import React from 'react'

export const OrderingUnavailableNotification = () => {
  return (
    <>
      <Alert
        variant="critical-subtle"
        icon={<ErrorFilledIcon />}
        fullWidth
        title="Ordering unavailable on this table"
      >
        Please speak to a member of staff
      </Alert>
    </>
  )
}

export const OrderingUnavailableMenuLayoutSpacer = () => {
  return <div className="h-16 w-full" />
}
