import { LockFilledIcon, StarFilledIcon } from '@mr-yum/frontend-ui'
import { AccentTag } from 'components/MenuItem/shared'
import React from 'react'

export interface RewardPriceTagProps {
  isAffordable: boolean
  price: number
  noun: string
}

export const RewardsPriceTag = ({
  isAffordable,
  price,
  noun,
}: RewardPriceTagProps) => (
  <AccentTag inactive={!isAffordable}>
    <div data-testid="rewards-price-tag" className="flex gap-[2px]">
      <div className="align-center my-auto mr-0.5 flex w-3 justify-center">
        {isAffordable ? (
          <StarFilledIcon
            width={12}
            height={12}
            data-testid="affordable-icon"
          />
        ) : (
          <LockFilledIcon
            width={12}
            height={12}
            data-testid="unaffordable-icon"
          />
        )}
      </div>
      <span>{Math.round(price)}</span>
      <span className="normal-case">{noun}</span>
    </div>
  </AccentTag>
)
