import { useAboutVenueQuery } from 'lib/gql'
import { OrderingTypeSlug } from 'lib/routes'
import React from 'react'

import { ViewOrderButton } from './ViewOrderButton'

interface Props {
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
  itemCount?: number
}

export const MenuFloatingButton = ({
  venueSlug,
  orderingTypeSlug,
  itemCount,
}: Props) => {
  const [{ data }] = useAboutVenueQuery({ variables: { venueSlug } })
  const venue = data?.guestVenue
  const canShowCart = venue?.ordering && orderingTypeSlug !== 'menu'

  if (!canShowCart) {
    return null
  }

  return (
    <ViewOrderButton
      venueSlug={venueSlug}
      orderingTypeSlug={orderingTypeSlug}
      itemCount={itemCount}
    />
  )
}
