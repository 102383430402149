import React from 'react'

/* 
  Code taken straight from Josh Comeau https://www.joshwcomeau.com/react/the-perils-of-rehydration/#abstractions-12

  The article goes into detail about the use case for this kind of component in SSR
*/

export const ClientOnly = ({ children }: { children: React.ReactNode }) => {
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return <>{children}</>
}
