import { TransformType } from '@mr-yum/cdn-image'
import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { ChevronDownIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { Image } from 'components/Shared/Image'
import { CategoryMenuBarContext } from 'contexts/CategoryMenuBarContext'
import { DeviceContext } from 'contexts/DeviceContext'
import { MenuQuery } from 'lib/gql'
import { OrderingTypeSlug, routes } from 'lib/routes'
import { observer } from 'mobx-react-lite'
import React, {
  createRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { MenuStoreContext } from 'stores/MenuStore'

import { CategorySheet } from './CategorySheet'
import { useFilteredMenuSections } from './hooks/useFilteredMenuSections'

interface Props {
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
  menuCategory: MenuQuery['guestMenuCategory']
  menuCategories: MenuQuery['guestMenuCategories']
  children?: ReactNode
}

const OFFSET_FOR_NAV_HEIGHT = -132
const FLEXIBLE_WAIT_TIMES_OFFSET = 40
const AUTO_SCROLL_TIMEOUT = 1250
const AUTO_SCROLL_COMPLETE_THRESHOLD = 1

export const CategoryMenuBar = observer(
  ({
    menuCategory,
    venueSlug,
    orderingTypeSlug,
    menuCategories,
    children,
  }: Props) => {
    const [categorySheetOpen, setCategorySheetOpen] = useState(false)
    const { setSearchQuery } = useContext(MenuStoreContext)
    const [categoryImageLoaded, setCategoryImageLoaded] = useState(false)
    const [autoScrollTargetY, setAutoScrollTargetY] = useState(0)
    const {
      currentSectionIndex,
      autoScrollActive,
      setAutoScrollActive,
      setCurrentSectionIndexes,
    } = useContext(CategoryMenuBarContext)
    const { iOS } = useContext(DeviceContext)
    const intl = useIntl()
    const menuSections = useFilteredMenuSections(menuCategory)
    const menuCategoryImage = menuCategory.image
    const sectionNavRef = useRef<HTMLDivElement>(null)
    const autoScrollRef = useRef<number | null>(null)

    const menuSectionsWithRef = useMemo(() => {
      return menuSections.map(() => createRef<HTMLDivElement>())
    }, [menuSections])

    // update section nav x based on currentSectionIndex
    useEffect(() => {
      const menuSectionRef = menuSectionsWithRef[currentSectionIndex]
      if (!menuSectionRef) return

      const sectionButton = menuSectionRef.current
      const sectionNav = sectionNavRef.current

      if (!sectionButton || !sectionNav) {
        return
      }

      const offsetX =
        currentSectionIndex === 0
          ? sectionNav.offsetWidth - sectionButton.offsetWidth
          : 16

      sectionNav.scrollTo({
        left: sectionButton.offsetLeft - offsetX,
        behavior: 'smooth',
      })
    }, [currentSectionIndex, iOS, menuSectionsWithRef])

    // autoscroll main window to current section after button click
    useEffect(() => {
      if (autoScrollActive) {
        if (autoScrollRef.current !== null) {
          cancelAnimationFrame(autoScrollRef.current)
        }

        // record the time in case we need to timeout
        const autoScrollStartedAt = Date.now()

        window.scrollTo({
          top: autoScrollTargetY,
          behavior: 'smooth',
        })

        // whether the autoscroll is complete:
        // - if the scroll is within a threshold of the target;
        // - or if it took too long for some reason.
        const isAutoScrollComplete = () => {
          const y = window.scrollY
          const deltaY = Math.abs(autoScrollTargetY - y)

          // measure elapsed time as a back up in case something goes wrong,
          // we MUST unset autoScrollActive for manual scrolling to work properly
          const elapsed = Date.now() - autoScrollStartedAt

          return (
            deltaY < AUTO_SCROLL_COMPLETE_THRESHOLD ||
            elapsed > AUTO_SCROLL_TIMEOUT
          )
        }

        // check if autoscroll is complete or keep trying with RAF
        const autoScrollStep = () => {
          const complete = isAutoScrollComplete()
          if (complete) {
            setAutoScrollActive(false)
            autoScrollRef.current = null
            return
          }
          autoScrollRef.current = requestAnimationFrame(autoScrollStep)
        }

        // start the check
        autoScrollStep()
      }
    }, [autoScrollActive, autoScrollTargetY, setAutoScrollActive])

    // clean up running autoscroll on unmount
    useEffect(
      () => () => {
        if (autoScrollRef.current !== null) {
          cancelAnimationFrame(autoScrollRef.current)
        }
      },
      [],
    )

    const handleCategoryButtonClick = useCallback(() => {
      // ensure category select is fully visible
      sectionNavRef.current?.scrollTo({
        left: 0,
        behavior: 'smooth',
      })
      setCategorySheetOpen(true)
    }, [])

    const handleCategoryChange = useCallback(
      (categorySlug: string) => {
        /**
         * We need to reset searchQuery when changing category
         */
        setSearchQuery('')
        if (autoScrollActive) {
          setAutoScrollActive(false)
        }
        if (autoScrollRef.current !== null) {
          cancelAnimationFrame(autoScrollRef.current)
        }
        void navigate(routes.menuCategory, {
          venueSlug,
          orderingTypeSlug,
          category: categorySlug,
        }).then(() => {
          window.scrollTo(0, 0)
        })
      },
      [
        autoScrollActive,
        orderingTypeSlug,
        setAutoScrollActive,
        setSearchQuery,
        venueSlug,
      ],
    )

    const handleCategorySelect = useCallback(
      (categorySlug: string) => () => {
        setCategorySheetOpen(false)
        setCategoryImageLoaded(false)
        handleCategoryChange(categorySlug)
      },
      [handleCategoryChange],
    )

    const handleSectionButtonClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        const index = Number(event.currentTarget.dataset.index)
        const sectionSlug = event.currentTarget.dataset.sectionslug
        const offset = Number(event.currentTarget.dataset.offset)

        if (!sectionSlug) return

        const sectionEl = document.getElementById(`section-${sectionSlug}`)

        if (!sectionEl) {
          return
        }

        const targetY = sectionEl.offsetTop + offset
        const deltaY = targetY - window.scrollY

        // if window scroll is already target scroll, bail
        if (Math.abs(deltaY) < AUTO_SCROLL_COMPLETE_THRESHOLD) {
          return
        }

        setAutoScrollActive(true)
        setAutoScrollTargetY(targetY)
        setCurrentSectionIndexes([index])
      },
      [setCurrentSectionIndexes, setAutoScrollActive],
    )

    return (
      <div className="js-dialog-offset border-b text-foreground">
        <nav
          aria-label="category and sections"
          ref={sectionNavRef}
          className="ml-4 flex h-14 items-center overflow-x-auto whitespace-nowrap pr-4 scrollbar-hide"
        >
          <button
            type="button"
            className={cn(
              'h-9 flex-shrink-0 rounded border bg-surface-subtle pr-2 text-foreground transition-colors my-label-sm hover:bg-surface',
              {
                'pl-1': menuCategoryImage,
                'pl-3': !menuCategoryImage,
              },
            )}
            onClick={handleCategoryButtonClick}
            aria-label={intl.formatMessage({
              defaultMessage: 'Open category modal',
              id: '9M4OON',
            })}
          >
            <span className="flex items-center space-x-2">
              {menuCategoryImage && (
                <span className="relative h-7 w-7">
                  <Image
                    image={menuCategoryImage}
                    alt={menuCategory.name}
                    transform={TransformType.SQUARE}
                    priority
                    className={cn('rounded-sm', {
                      'opacity-1': categoryImageLoaded,
                      'opacity-0': !categoryImageLoaded,
                    })}
                    layout="fixed"
                    width={28}
                    height={28}
                    onLoad={() => setCategoryImageLoaded(true)}
                  />
                  {!categoryImageLoaded && (
                    <span className="bg-suface-subtle absolute left-0 top-0 h-full w-full animate-pulse object-cover"></span>
                  )}
                </span>
              )}
              <span>{menuCategory.name}</span>
              <ChevronDownIcon />
            </span>
          </button>

          {menuSections && (
            <div className="flex w-full space-x-2 px-2">
              {menuSections.map((menuSection, index) => {
                return (
                  <div
                    key={menuSection.id}
                    ref={menuSectionsWithRef[index]}
                    className="last:pr-4 hover:cursor-pointer"
                  >
                    <button
                      data-index={index}
                      data-sectionslug={menuSection.slug}
                      id={menuSection.id}
                      data-offset={
                        children
                          ? OFFSET_FOR_NAV_HEIGHT - FLEXIBLE_WAIT_TIMES_OFFSET
                          : OFFSET_FOR_NAV_HEIGHT
                      }
                      onClick={handleSectionButtonClick}
                    >
                      <span
                        className={cn(
                          'flex h-9 items-center justify-center rounded border px-4 py-2 transition-colors',
                          {
                            'bg-surface-subtle text-foreground my-body-sm hover:bg-surface':
                              currentSectionIndex !== index,
                          },
                          {
                            'border-[theme(backgroundColor.surface-inverted) bg-surface-inverted text-foreground-inverted my-label-sm':
                              currentSectionIndex === index,
                          },
                        )}
                      >
                        {menuSection.name}
                      </span>
                    </button>
                  </div>
                )
              })}
            </div>
          )}
        </nav>
        {children && <div className="px-4 pb-3">{children}</div>}
        <CategorySheet
          categorySheetOpen={categorySheetOpen}
          setCategorySheetOpen={setCategorySheetOpen}
          menuCategories={menuCategories}
          activeCategory={menuCategory}
          handleCategorySelect={handleCategorySelect}
        />
      </div>
    )
  },
)

CategoryMenuBar.displayName = 'CategoryMenuBar'
