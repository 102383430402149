import { TransformType } from '@mr-yum/cdn-image'
import { ChevronRightIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { Sheet } from 'components/Common/Sheet'
import { FlexibleWaitTimesLabel } from 'components/FlexibleWaitTimes/FlexibleWaitTimesLabel'
import { ResponsiveModal } from 'components/ResponsiveModal'
import { Image } from 'components/Shared/Image'
import { GuestImage, GuestWaitTime, MenuQuery } from 'lib/gql'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface CategorySheetProps {
  categorySheetOpen: boolean
  setCategorySheetOpen: React.Dispatch<React.SetStateAction<boolean>>
  menuCategories: MenuQuery['guestMenuCategories']
  activeCategory: MenuQuery['guestMenuCategory']
  handleCategorySelect: (categorySlug: string) => () => void
}

interface CategoryItem {
  slug: string
  name: string
  image?: GuestImage
  extendedWaitTime?: GuestWaitTime
}

const CategorySheetItem = ({
  category,
  isActive,
  handleCategorySelect,
}: {
  category: CategoryItem
  isActive: boolean
  handleCategorySelect: (categorySlug: string) => () => void
}) => {
  return (
    <button
      className={cn(
        'flex min-h-[80px] w-full cursor-pointer items-center justify-between rounded-lg border',
        {
          'cursor-not-allowed border-2 border-bold': isActive,
        },
      )}
      onClick={handleCategorySelect(category.slug)}
    >
      {category.image && (
        <span className="relative h-[80px] w-[80px] flex-shrink-0 overflow-hidden rounded-l-md">
          <Image
            transform={TransformType.SQUARE}
            image={category.image}
            alt={category.name}
            layout="fill"
            objectFit="cover"
            sizes="60px"
          />
        </span>
      )}

      <span className="flex-grow px-4 py-2 text-left my-label-md">
        {category.name}
        {category.extendedWaitTime && (
          <span className="mt-2 block w-fit">
            <FlexibleWaitTimesLabel
              estimate={category.extendedWaitTime.estimate}
              isItemWaitTime={false}
              waitTimeInMilliseconds={
                category.extendedWaitTime.estimateInMilliseconds ?? 0
              }
            />
          </span>
        )}
      </span>

      <ChevronRightIcon className="mr-3 flex-shrink-0" />
    </button>
  )
}

export const CategorySheet = ({
  categorySheetOpen,
  setCategorySheetOpen,
  menuCategories,
  activeCategory,
  handleCategorySelect,
}: CategorySheetProps) => {
  return (
    <ResponsiveModal onClose={() => setCategorySheetOpen(false)}>
      {categorySheetOpen && (
        <Sheet
          isCloseable={true}
          showBack={false}
          onClose={() => setCategorySheetOpen(false)}
          title={<FormattedMessage defaultMessage="Select menu" id="Ak6K2+" />}
        >
          <div className="max-h-[460px] space-y-4 overflow-y-auto p-4">
            {menuCategories.map((category) => (
              <CategorySheetItem
                category={{
                  name: category.name,
                  slug: category.slug,
                  image: category.image ?? undefined,
                  extendedWaitTime: category.extendedWaitTime ?? undefined,
                }}
                isActive={activeCategory.slug === category.slug}
                handleCategorySelect={handleCategorySelect}
                key={category.slug}
              />
            ))}
          </div>
        </Sheet>
      )}
    </ResponsiveModal>
  )
}
