import { Button, CloseIcon, SVGNoFilterResults } from '@mr-yum/frontend-ui'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  dietaryLabelForLanguageByValue,
  DietaryPref,
} from 'stores/enums/dietaryPreferences'
import { MenuStoreContext } from 'stores/MenuStore'

export const NoFilterResults = observer(() => {
  const { dietaryPrefs, setDietaryPrefs, setSearchQuery } =
    useContext(MenuStoreContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const updateDietaryPrefs = useCallback(
    (dietaryPref: DietaryPref) => {
      setSearchQuery('')
      const updatedDietaryPrefs = dietaryPrefs.filter(
        (pref) => pref !== dietaryPref,
      )
      setDietaryPrefs(updatedDietaryPrefs)
    },
    [dietaryPrefs, setDietaryPrefs, setSearchQuery],
  )

  return (
    <div className="flex flex-col items-center space-y-6 pb-6 text-center text-foreground">
      <SVGNoFilterResults width="120px" height="117px" />
      <div className="flex flex-col space-y-2">
        <p className="my-heading-md">
          <FormattedMessage
            defaultMessage="Oh snap! We can't find any matches!"
            id="mCHw1e"
          />
        </p>
        <p className="my-body-sm">
          <FormattedMessage
            defaultMessage="Try removing some of your filters."
            id="gLnKpz"
          />
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-2">
        {dietaryPrefs.map((dietaryPref) => {
          const dietaryLabel = dietaryLabelForLanguageByValue.en[dietaryPref]
          return (
            <Button
              type="button"
              variant="outline"
              key={dietaryLabel}
              onClick={() => updateDietaryPrefs(dietaryPref)}
              rightIcon={<CloseIcon />}
            >
              {dietaryLabel}
            </Button>
          )
        })}
      </div>
    </div>
  )
})

NoFilterResults.displayName = 'NoFilterResults'
