import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Button, ErrorFormat } from '@mr-yum/frontend-ui'
import SvgSadBeerBoi from 'components/Icons/SvgSadBeerBoi'
import { OrderingTypeSlug, routes } from 'lib/routes'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  ItemDetailsContent,
  ItemDetailsContentInner,
  ItemDetailsName,
  ItemDetailsWrapper,
} from './shared'

interface Props {
  error: Error
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
}

export const ItemDetailsError = ({
  error,
  venueSlug,
  orderingTypeSlug,
}: Props) => {
  return (
    <>
      <ItemDetailsWrapper>
        <ItemDetailsContent>
          <ItemDetailsContentInner>
            <div className="mb-8 flex-1 flex-col items-center text-center">
              <div className="mb-4">
                <SvgSadBeerBoi
                  width="147px"
                  height="120px"
                  className="inline-block"
                />
              </div>

              <div className="mb-4">
                <ItemDetailsName>
                  <FormattedMessage defaultMessage="Oh snap!" id="0G3+e2" />
                </ItemDetailsName>
                {error && (
                  <div className="my-body-md">
                    <ErrorFormat error={error} />
                  </div>
                )}
              </div>

              <RouteLink
                route={routes.menuCategories}
                pathData={{
                  venueSlug,
                  orderingTypeSlug,
                }}
                replace
              >
                <Button
                  variant="primary-alternative"
                  as="a"
                  className="!inline-block"
                >
                  <FormattedMessage defaultMessage="Back to menu" id="AgoOEq" />
                </Button>
              </RouteLink>
            </div>
          </ItemDetailsContentInner>
        </ItemDetailsContent>
      </ItemDetailsWrapper>
    </>
  )
}
