import { CheckboxV2 } from '@mr-yum/frontend-ui'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface SelectOption {
  label: string
  value: string
  isSelected: boolean
}

interface Props {
  onItemSelect: ({ item }: any) => void
  items: SelectOption[]
}

export const PreferencesMenu = ({ onItemSelect, items }: Props) => {
  return (
    <div>
      <h3 className="p-4 pt-8 my-heading-sm">
        <FormattedMessage defaultMessage="Dietaries" id="mUal0E" />
      </h3>
      <ul
        role="listbox"
        tabIndex={0}
        className="relative m-0 list-none p-0 outline-none"
      >
        {items.map((item, index) => (
          <li
            key={index}
            onClick={() => onItemSelect({ item })}
            className="cursor-pointer"
            role="option"
            aria-disabled="false"
            aria-selected={item.isSelected}
          >
            <div className="transitions-all flex items-center justify-between p-4 text-foreground hover:bg-interactive-subtle-surface-hover">
              <div className="mr-3 my-body-md">{item.label}</div>
              <CheckboxV2
                checked={item.isSelected}
                onCheckedChange={() => onItemSelect({ item })}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
