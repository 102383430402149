import { DEFAULT_MAX_QUANTITY } from 'components/MenuItem/utils'
import { GuestMenuItem } from 'lib/gql'
import {
  ModifierGroupPartsFragment,
  UpgradeGroupPartsFragment,
  UpsellGroupPartsFragment,
} from 'lib/gql.types'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

import { getOptionGroupSchema } from './getOptionGroupSchema'

export const getValidationSchema = (
  {
    menuItem,
    upgradeGroups,
    upsellGroups,
    modifierGroups,
  }: {
    menuItem: Pick<
      GuestMenuItem,
      'minQuantity' | 'inventoryCount' | 'inventoryEnabled' | 'maxQuantity'
    >
    upgradeGroups: UpgradeGroupPartsFragment[]
    upsellGroups: UpsellGroupPartsFragment[]
    modifierGroups: ModifierGroupPartsFragment[]
  },
  intl: IntlShape,
) => {
  return Yup.object().shape({
    item: Yup.object({
      id: Yup.string().required(),
      quantity: Yup.number()
        .min(menuItem.minQuantity || 1, () =>
          intl.formatMessage(
            {
              defaultMessage:
                'This menu item has a minimum order requirement of {itemQuantity}.',
              id: 'f3EIiT',
            },
            { itemQuantity: menuItem.minQuantity || 1 },
          ),
        )
        .max(
          Math.min(
            menuItem.inventoryEnabled && menuItem.inventoryCount != null
              ? Math.max(0, menuItem.inventoryCount)
              : DEFAULT_MAX_QUANTITY,
            menuItem.maxQuantity || DEFAULT_MAX_QUANTITY,
          ),
          () =>
            // if the lower limit is inventoryCount
            menuItem.inventoryEnabled &&
            menuItem.inventoryCount != null &&
            menuItem.inventoryCount <=
              (menuItem.maxQuantity || DEFAULT_MAX_QUANTITY)
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      'There is only {inventoryCount} {inventoryCount, plural, one {item} other {items}} left of this product',
                    id: 'iGejse',
                  },
                  { inventoryCount: menuItem.inventoryCount || 1 },
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      'This menu item has a maximum order requirement of {maxQuantity}.',
                    id: 'rnf3FZ',
                  },
                  { maxQuantity: menuItem.maxQuantity || DEFAULT_MAX_QUANTITY },
                ),
        )
        .required(),
    }),
    upgrades: getOptionGroupSchema(upgradeGroups, intl, 'upgrades'),
    modifiers: getOptionGroupSchema(modifierGroups, intl, 'modifiers'),
    upsells: getOptionGroupSchema(upsellGroups, intl, 'upsells'),
  })
}
