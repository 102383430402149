import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { ModalContainer } from '@mr-yum/frontend-ui'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { routes } from 'lib/routes'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { ItemDetails } from './ItemDetails'

interface Props {
  itemSlug: string
  isOpen: boolean
  hasImage?: boolean
}

export const ItemModal = ({ isOpen, hasImage }: Props) => {
  const { query } = useRouter()
  const { orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const handleClose = useCallback(() => {
    void navigate(
      routes.menuCategory,
      {
        venueSlug,
        orderingTypeSlug,
        category: String(query.category),
      },
      {
        scroll: false,
      },
    )
  }, [query, venueSlug, orderingTypeSlug])

  return (
    <ModalContainer isOpen={isOpen} onClose={handleClose}>
      <div className="modal-content overflow-auto md:overflow-hidden md:rounded">
        <ItemDetails hasImage={hasImage} handleClose={handleClose} />
      </div>
    </ModalContainer>
  )
}
