import { redirect } from '@mr-yum/frontend-core/dist/services/redirect'
import { CenterSpinner, useBreakpoint } from '@mr-yum/frontend-ui'
import { CombinedError } from '@urql/core'
import cn from 'classnames'
import { ClientOnly } from 'components/Common/ClientOnly'
import { NotFound } from 'components/Common/NotFound'
import { FlexibleWaitTimesLabel } from 'components/FlexibleWaitTimes/FlexibleWaitTimesLabel'
import { CategoryHeaderBar } from 'components/Menu/CategoryHeaderBar'
import { CategoryMenuBar } from 'components/Menu/CategoryMenuBar'
import { useTableRule } from 'components/Menu/hooks/useTableRule'
import { MenuFloatingButton } from 'components/Menu/MenuFloatingButton'
import { PopupNotificationModal } from 'components/PopupNotification/PopupNotificationModal'
import { RewardsHeader } from 'components/Rewards/RewardsHeader'
import { RewardsSignUpBanner } from 'components/Rewards/RewardsSignUpBanner'
import {
  OrderingUnavailableMenuLayoutSpacer,
  OrderingUnavailableNotification,
} from 'components/TableOff/OrderingUnavailableNotification'
import { RewardsContext } from 'contexts/RewardsContext'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { reportError } from 'lib/bugsnag'
import { GuestWaitTime, MenuQuery, MenuVenueQuery, OrderingType } from 'lib/gql'
import { OrderingTypeSlug, routes } from 'lib/routes'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import React, {
  cloneElement,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
} from 'react'

import { Footer } from './Footer'

interface MenuLayoutProps {
  loading?: boolean
  loaderComponent?: React.ReactNode
  error?: CombinedError | null
  venue?: MenuVenueQuery['guestVenue']
  menuCategory?: MenuQuery['guestMenuCategory']
  menuCategories?: MenuQuery['guestMenuCategories']
  category?: string
  itemCount?: number
  menuCategoryWaitTime?: GuestWaitTime
}

interface MenuNavBarProps {
  venue?: MenuVenueQuery['guestVenue']
  menuCategory?: MenuQuery['guestMenuCategory']
  menuCategories?: MenuQuery['guestMenuCategories']
  menuCategoryWaitTime?: GuestWaitTime
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
  tableAvailable: boolean
  isMobile: boolean
}

const MenuNavBar = ({
  venue,
  menuCategories,
  menuCategory,
  menuCategoryWaitTime,
  venueSlug,
  orderingTypeSlug,
  tableAvailable,
  isMobile,
}: MenuNavBarProps) => {
  return (
    <>
      <div className="fixed top-14 z-[10] w-full bg-surface-ui-background">
        {!tableAvailable && <OrderingUnavailableNotification />}
        {venue &&
          menuCategories &&
          menuCategory &&
          cloneElement(
            <CategoryMenuBar
              venueSlug={venueSlug}
              orderingTypeSlug={orderingTypeSlug!}
              menuCategory={menuCategory}
              menuCategories={menuCategories}
            />,
            {},
            !isMobile && menuCategoryWaitTime && (
              <div className="w-fit rounded-md bg-surface-subtle px-3 py-2">
                <FlexibleWaitTimesLabel
                  estimate={menuCategoryWaitTime.estimate}
                  isItemWaitTime={false}
                  waitTimeInMilliseconds={
                    menuCategoryWaitTime.estimateInMilliseconds ?? 0
                  }
                  category={menuCategory.name}
                />
              </div>
            ),
          )}
      </div>
    </>
  )
}

const MenuNavBarSpacer = () => <div className="h-28 w-full" />

const MenuCategoryWaitTimeSpacer = ({ isMobile }: { isMobile: boolean }) => (
  <div className={cn('w-full', isMobile ? 'h-8' : 'h-12')} />
)

const MenuSectionLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-surface-ui-background px-4 py-5 text-foreground md:px-5 md:py-8">
      {children}
    </div>
  )
}

export const MenuLayout = ({
  loading,
  loaderComponent,
  error,
  venue,
  menuCategory,
  menuCategories,
  category,
  menuCategoryWaitTime,
  children,
  itemCount,
}: PropsWithChildren<MenuLayoutProps>) => {
  const {
    rewardsCategory,
    flags: { rewardsBalanceEnabled },
  } = useContext(RewardsContext)
  const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const breakpoint = useBreakpoint()
  const isMobile = ['sm'].includes(breakpoint)
  const { tableAvailable } = useTableRule()

  // keep customer ordering type in sync with url, and prevent visiting invalid ordering type menus
  useEffect(() => {
    // in all cases, wait for venue to load
    if (!venue) return

    const enabledOrderingTypes: OrderingType[] = flow(
      filter({ enabled: true }),
      map('orderingType'),
    )(venue.orderingTypes)

    // visual menu
    if (orderingType === OrderingType.Menu) return

    // if slug orderingType isn't supported, or is specified but the venue doesn't have ordering, just bounce to venue landing
    if (!venue.ordering || !enabledOrderingTypes.includes(orderingType)) {
      return redirect(routes.venue.toPath!({ venueSlug }))
    }
  }, [venue, venueSlug, orderingType])

  useEffect(() => {
    if (error) {
      reportError(error)
    }
  }, [error])

  if (error) {
    return <NotFound error={error} />
  }

  const showRewardsHeader =
    rewardsBalanceEnabled && category === rewardsCategory

  return (
    <>
      <PopupNotificationModal venueSlug={venueSlug} />
      <CategoryHeaderBar
        venueName={venue?.name}
        venueSlug={venueSlug}
        orderingTypeSlug={orderingTypeSlug}
      />
      <ClientOnly>
        <MenuNavBar
          venue={venue}
          menuCategories={menuCategories}
          menuCategory={menuCategory}
          menuCategoryWaitTime={menuCategoryWaitTime}
          orderingTypeSlug={orderingTypeSlug}
          venueSlug={venueSlug}
          tableAvailable={tableAvailable}
          isMobile={isMobile}
        />
      </ClientOnly>
      {isMobile && menuCategoryWaitTime && menuCategory && (
        <div className="fixed top-28 z-[9] w-full bg-surface-bright px-3 py-2">
          <FlexibleWaitTimesLabel
            estimate={menuCategoryWaitTime.estimate}
            isItemWaitTime={false}
            waitTimeInMilliseconds={
              menuCategoryWaitTime.estimateInMilliseconds ?? 0
            }
            category={menuCategory.name}
          />
        </div>
      )}

      <MenuNavBarSpacer />
      {!tableAvailable && <OrderingUnavailableMenuLayoutSpacer />}
      {menuCategoryWaitTime && (
        <MenuCategoryWaitTimeSpacer isMobile={isMobile} />
      )}
      {showRewardsHeader && <RewardsSignUpBanner />}
      {showRewardsHeader && <RewardsHeader />}
      <ClientOnly>
        <MenuSectionLayout>
          <div>{loading && (loaderComponent || <CenterSpinner />)}</div>
          <div>{!loading && children}</div>
        </MenuSectionLayout>
        <MenuFloatingButton
          venueSlug={venueSlug}
          orderingTypeSlug={orderingTypeSlug!}
          itemCount={itemCount}
        />
      </ClientOnly>
      {!loading && <Footer hasFloatingButton />}
    </>
  )
}
