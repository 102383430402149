import { Button } from '@mr-yum/frontend-ui'
import BannerGrid from 'components/Icons/BannerGrid'
import { RewardsContext } from 'contexts/RewardsContext'
import { useDisclosure } from 'hooks/useDisclosure'
import { useLogger } from 'hooks/useLogger'
import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { HowItWorksSheet } from './HowItWorksSheet'
import PointsDisplay from './PointsDisplay'

export const RewardsHeader = () => {
  const { logEvent } = useLogger()
  const { balance, noun, fetching } = useContext(RewardsContext)

  const { setOpen, isOpen, setClosed } = useDisclosure(false)

  const handleOnHowItWorksOpen = useCallback(() => {
    logEvent('Opened "how it works" sheet')
    setOpen()
  }, [logEvent, setOpen])

  const handleOnHowItWorksClose = useCallback(() => {
    logEvent('Closed "how it works" sheet')
    setClosed()
  }, [logEvent, setClosed])

  return (
    <>
      <header className="relative h-[200px] overflow-hidden bg-surface-ui-navbar text-center">
        <BannerGrid className="absolute top-0 -scale-x-100 text-interactive-accent-foreground-active" />
        <BannerGrid className="absolute right-0 top-0 text-interactive-accent-foreground-active" />

        <div className="space-between mx-auto flex h-full flex-col">
          <div className="mt-8 flex flex-col">
            <PointsDisplay
              balance={balance}
              noun={noun}
              isFetching={fetching}
            />
          </div>

          <div className="mb-8 mt-auto flex justify-center">
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={handleOnHowItWorksOpen}
            >
              <FormattedMessage defaultMessage="How It Works" id="/oNE8Q" />
            </Button>
          </div>
        </div>
      </header>

      {isOpen && <HowItWorksSheet onClose={handleOnHowItWorksClose} />}
    </>
  )
}
