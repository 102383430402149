import React from 'react'
import ReactMarkdown from 'react-markdown'

interface Props {
  children: string
}

export const Markdown = ({ children, ...props }: Props) => (
  <ReactMarkdown
    allowedTypes={['text', 'paragraph', 'emphasis', 'strong', 'link']}
    unwrapDisallowed
    linkTarget="_blank"
    renderers={{
      paragraph: ({ children }) => (
        <p className="mb-4 text-inherit my-body-md" {...props}>
          {children}
        </p>
      ),
      link: ({ children, target, href }) => (
        <a className="text-inherit underline" target={target} href={href}>
          {children}
        </a>
      ),
    }}
  >
    {children}
  </ReactMarkdown>
)
