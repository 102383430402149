import {
  DEFAULT_MAX_QUANTITY,
  getCombinedItemsTotalQuantity,
} from 'components/MenuItem/utils'
import { MenuItemSelectRequirement, MenuItemSelectType } from 'lib/gql'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

import { Item } from '../types'

export const getItemSchema = (
  minQuantity: number,
  selectRequirement: MenuItemSelectRequirement,
  maxQuantity: number,
  selectType: MenuItemSelectType,
  intl: IntlShape,
) => {
  return Yup.array()
    .of(
      Yup.object({
        id: Yup.string(),
        quantity: Yup.number(),
      }),
    )
    .test({
      name: 'required',
      test: (items: Item[]) => {
        if (selectRequirement === MenuItemSelectRequirement.Required) {
          const itemsWithQuantity = items.filter((item) => {
            return item.quantity > 0
          })

          return itemsWithQuantity.length > 0
        }

        return true
      },
      message:
        selectType === MenuItemSelectType.Single
          ? intl.formatMessage({
              defaultMessage: 'Please make a selection.',
              id: 'f+6tXq',
            })
          : intl.formatMessage(
              {
                defaultMessage:
                  'Please select at least {minQuantity} {minQuantity, plural, one {item} other{items}}.',
                id: 'NzzLAJ',
              },
              { minQuantity: minQuantity || 1 },
            ),
      exclusive: true,
    })
    .test({
      name: 'min',
      test: (items: Item[]) => {
        const min = minQuantity || 0
        const combinedItemsTotalQuantity = getCombinedItemsTotalQuantity(items)

        return (
          combinedItemsTotalQuantity === 0 || combinedItemsTotalQuantity >= min
        )
      },
      message: intl.formatMessage(
        {
          defaultMessage:
            'Please select at least {minQuantity} {minQuantity, plural, one {item} other{items}}.',
          id: 'NzzLAJ',
        },
        { minQuantity: 0 || minQuantity },
      ),
      exclusive: false,
    })
    .test({
      name: 'max',
      test: (items: Item[]) => {
        const combinedItemsTotalQuantity = getCombinedItemsTotalQuantity(items)

        return combinedItemsTotalQuantity <= maxQuantity
      },
      message:
        selectRequirement === MenuItemSelectRequirement.Optional
          ? intl.formatMessage(
              {
                defaultMessage:
                  'Please select at most {maxQuantity} {maxQuantity, plural, one {item} other{items}}.',
                id: 'W3liyN',
              },
              { maxQuantity },
            )
          : intl.formatMessage(
              {
                defaultMessage:
                  'Please select only {maxQuantity} {maxQuantity, plural, one {item} other{items}}.',
                id: 'SQbYcB',
              },
              { maxQuantity: maxQuantity || DEFAULT_MAX_QUANTITY },
            ),
      exclusive: true,
    })
}
