import Fuse from 'fuse.js'
import { MenuQuery } from 'lib/gql'
import { MenuItemPartsFragment, MenuSectionFragment } from 'lib/gql.types'
import filter from 'lodash/fp/filter'
import flatten from 'lodash/fp/flatten'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import includes from 'lodash/fp/includes'
import intersection from 'lodash/fp/intersection'
import isEqual from 'lodash/fp/isEqual'
import map from 'lodash/fp/map'
import { values } from 'mobx'
import { useCallback, useContext, useMemo } from 'react'
import { MenuStoreContext } from 'stores/MenuStore'

const searchOptions = {
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['name', 'description'],
}

/**
 * We need to filter out items that should be hidden
 */

export function useFilteredMenuSections(
  menuCategory: MenuQuery['guestMenuCategory'] | undefined,
): MenuSectionFragment[] {
  const { searchQuery, dietaryPrefs } = useContext(MenuStoreContext)

  const selectedDietaryPrefs = useMemo(
    () => values(dietaryPrefs),
    [dietaryPrefs],
  )

  const sections = useMemo(
    () => get('menuSections')(menuCategory),
    [menuCategory],
  )

  /**
   * We need to grab just the items from all the sections
   * for Fuse
   */
  const menuItems = useMemo(
    () => flow(map('menuItems'), flatten)(sections),
    [sections],
  )

  /**
   * We need to filter menuItems by dietaryPrefs
   */
  const byDietaryPreferences = useCallback(
    ({ filterTags }: MenuItemPartsFragment) =>
      flow(
        intersection(selectedDietaryPrefs),
        isEqual(selectedDietaryPrefs),
      )(filterTags as any),
    [selectedDietaryPrefs],
  )

  /**
   * We need to filter items that match with our search query
   */
  const fuse = useMemo(() => new Fuse(menuItems, searchOptions), [menuItems])
  const bySearchQuery = useCallback(
    (menuItem: MenuItemPartsFragment) => {
      const searchResult = fuse.search(searchQuery)
      const searchResultIds = (searchQuery && map('id')(searchResult)) || []
      return searchQuery ? includes(menuItem.id)(searchResultIds) : true
    },
    [fuse, searchQuery],
  )

  /**
   * We need an array of ids that match all our filters
   */
  const matchingItemsIds = useMemo(
    () =>
      flow(
        filter(byDietaryPreferences),
        filter(bySearchQuery),
        map('id'),
      )(menuItems),
    [menuItems, byDietaryPreferences, bySearchQuery],
  )

  /**
   * We need to return sections along with their filtered items
   * and only include sections that have matches
   */
  const filteredSections = useMemo(
    () =>
      flow(
        map(
          ({
            menuItems,
            ...section
          }: MenuSectionFragment): MenuSectionFragment => ({
            ...section,
            id: section.id,
            name: section.name,
            slug: section.slug,
            menuItems: filter(({ id }: MenuItemPartsFragment) =>
              includes(id)(matchingItemsIds),
            )(menuItems),
          }),
        ),
        // No sections without items
        filter(({ menuItems }: MenuSectionFragment) => menuItems.length > 0),
      )(sections),
    [sections, matchingItemsIds],
  )

  return filteredSections
}
