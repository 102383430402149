import { isUpsellGroup } from 'components/MenuItemModal/guards'
import { DisplayViewSelectRequirement, OrderingType } from 'lib/gql'
import {
  ModifierGroupPartsFragment,
  UpgradeGroupPartsFragment,
  UpsellGroupPartsFragment,
} from 'lib/gql.types'
import { useCallback, useMemo } from 'react'

const withoutHiddenGroups = (
  group: UpsellGroupPartsFragment | ModifierGroupPartsFragment,
) => group.displayViewSelectRequirement !== DisplayViewSelectRequirement.Hidden

const withOnlyAvailableUpsells = ({
  upsells,
  ...upsellGroup
}: UpsellGroupPartsFragment): UpsellGroupPartsFragment => ({
  ...upsellGroup,
  upsells: upsells.filter((upsell) => upsell.menuItem.isAvailableForOrdering),
})

const withOnlyAvailableUpgrades = ({
  upgrades,
  ...upgradeGroup
}: UpgradeGroupPartsFragment): UpgradeGroupPartsFragment => ({
  ...upgradeGroup,
  upgrades: upgrades.filter(
    (upgrade) => upgrade.menuItem.isAvailableForOrdering,
  ),
})

/**
 * We want to filter out groups that have
 * all their items in an unavailable state
 */
const byEveryItemNotUnavailable = (
  group: UpgradeGroupPartsFragment | UpsellGroupPartsFragment,
) =>
  isUpsellGroup(group)
    ? !group.upsells.every((upsell) => !upsell.menuItem.isAvailableForOrdering)
    : !group.upgrades.every(
        (upgrade) => !upgrade.menuItem.isAvailableForOrdering,
      )

export interface UpgradeModifierUpsell {
  modifierGroups?: ModifierGroupPartsFragment[] | null
  upsellGroups?: UpsellGroupPartsFragment[] | null
  upgradeGroup?: UpgradeGroupPartsFragment | null
}

export type GroupsConfig = {
  upgradeGroups: UpgradeGroupPartsFragment[]
  modifierGroups: ModifierGroupPartsFragment[]
  upsellGroups: UpsellGroupPartsFragment[]
}

export const useFilteredItemModifiers = (
  orderingType: OrderingType,
  menuItem?: UpgradeModifierUpsell,
): GroupsConfig => {
  /**
   * We only want options available for
   * the currently active orderingType or default to DINE_IN
   */
  const byOrderingType = useCallback(
    ({
      orderingTypes,
    }:
      | ModifierGroupPartsFragment
      | UpsellGroupPartsFragment
      | UpgradeGroupPartsFragment) => {
      return !orderingTypes || orderingTypes.length === 0
        ? true
        : orderingTypes.includes(orderingType || OrderingType.DineIn)
    },

    [orderingType],
  )

  const dynamicUpsellsWhenVisualMenu = useCallback(
    ({ isDynamic }: UpsellGroupPartsFragment): boolean => {
      if (orderingType && isDynamic) {
        // dynamic upsells sets orderingTypes to Null so
        return isDynamic && orderingType !== OrderingType.Menu
      }
      return true
    },
    [orderingType],
  )

  const modifierGroups = useMemo(
    () =>
      (menuItem?.modifierGroups ?? [])
        .filter(byOrderingType)
        .filter(withoutHiddenGroups)
        .filter((modifierGroup) => modifierGroup.modifiers.length > 0),
    [menuItem?.modifierGroups, byOrderingType],
  )

  const upsellGroups = useMemo(
    () =>
      (menuItem?.upsellGroups ?? [])
        .filter(byOrderingType)
        .filter((upsellGroup) => upsellGroup.available)
        .filter(byEveryItemNotUnavailable)
        .filter(withoutHiddenGroups)
        .filter(dynamicUpsellsWhenVisualMenu)
        .map(withOnlyAvailableUpsells),
    [byOrderingType, menuItem?.upsellGroups, dynamicUpsellsWhenVisualMenu],
  )

  const upgradeGroups = useMemo(
    () =>
      (menuItem?.upgradeGroup ? [menuItem.upgradeGroup] : [])
        .filter(byOrderingType)
        .filter(byEveryItemNotUnavailable)
        .map(withOnlyAvailableUpgrades)
        .filter((upgradeGroup) => upgradeGroup.upgrades.length > 0),
    [byOrderingType, menuItem?.upgradeGroup],
  )

  return {
    upgradeGroups,
    modifierGroups,
    upsellGroups: orderingType ? upsellGroups : [], // No upsells when visual menu
  }
}
