import React, { HTMLAttributes } from 'react'

export const MenuSectionWrapper = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <section className="mb-[42px] md:mb-12" {...props}>
    {children}
  </section>
)

export const MenuSectionHeader = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <header className="mb-4 flex flex-col sm:flex-wrap" {...props}>
    {children}
  </header>
)

export const MenuSectionTitle = ({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) => (
  <h1 className="my-heading-lg sm:grow" {...props}>
    {children}
  </h1>
)

export const MenuSectionSubtitle = ({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) => (
  <h2 className="my-body-md sm:order-last sm:w-full" {...props}>
    {children}
  </h2>
)
