import { CartItemInput, OrderingType } from 'lib/gql'

import { FormValues } from '../types'
import { mapModifiersValueToInput } from './mapModifiersValueToInput'
import { mapSingleOptionGroupToCartUpsell } from './mapSingleOptionGroupToCartUpsell'
import { mapUpsellsValueToInput } from './mapUpsellsValueToInput'

export const serializeFormValuesToInput = (
  orderingType: OrderingType,
  venueSlug: string,
  formValues: FormValues,
): CartItemInput => {
  return {
    orderingType,
    venueSlug,
    item: formValues.item,
    upgrades: mapSingleOptionGroupToCartUpsell(formValues.upgrades),
    modifiers: mapModifiersValueToInput(
      Object.values(formValues.modifiers).flat(),
    ),
    upsells: mapUpsellsValueToInput(formValues.upsells),
  }
}
