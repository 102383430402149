import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'

export const useDebouncedValue = (value: any, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounce(() => {
      setDebouncedValue(value)
    }, delay),
    [delay, setDebouncedValue],
  )

  return debouncedValue
}
