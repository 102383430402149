import {
  convert24To12,
  WEEKDAYS,
} from '@mr-yum/frontend-core/dist/support/dates'
import { Modal } from '@mr-yum/frontend-ui'
import { PreorderingContext } from 'components/Menu/MenuPage'
import { isOrderingTypeAvailable } from 'components/Venue/utils/venueUtils'
import {
  MenuCategoriesQuery,
  MenuVenueQuery,
  OrderingType,
  TimeSlot,
  VenueLandingQuery,
} from 'lib/gql'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props {
  className?: string
  venue?:
    | MenuVenueQuery['guestVenue']
    | MenuCategoriesQuery['guestVenue']
    | VenueLandingQuery['guestVenue']
  orderingType: OrderingType
}

export const VenueClosedBanner = ({
  className,
  venue,
  orderingType,
}: Props) => {
  const canPreorder = useContext(PreorderingContext)
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  if (!venue) return null

  if (orderingType === OrderingType.Menu) {
    return null
  }

  const isAcceptingOrders =
    venue.ordering &&
    venue.isOrderingAvailable &&
    isOrderingTypeAvailable(venue as any, orderingType)

  if (!isAcceptingOrders) {
    const isClosed = venue.isClosed
    const hasHours = !!venue.hours

    return (
      <div className={className}>
        <div className="w-full bg-surface-bright p-3 text-foreground">
          <p className="flex-1">
            {isClosed ? (
              <FormattedMessage
                defaultMessage="Sorry we’re closed {canPreorder, select, true {but open for pre-ordering!} other {at the moment.}}"
                id="PKrbl/"
                values={{ canPreorder }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Sorry we’re closed, please check back soon{hasHours, select, true { or} other {!}}"
                id="i6Fba+"
                values={{ hasHours }}
              />
            )}

            {hasHours && (
              <>
                {' '}
                <a
                  className="cursor-pointer text-inherit underline"
                  onClick={() => setOpen(true)}
                  aria-label={intl.formatMessage({
                    defaultMessage: 'See our opening hours',
                    id: 'szQI34',
                  })}
                >
                  <FormattedMessage
                    defaultMessage="{isClosed, select, true {See our opening hours.} other {see our opening hours.}}"
                    id="HkSUIY"
                    values={{
                      isClosed: isClosed,
                    }}
                  />
                </a>
              </>
            )}
          </p>
        </div>

        <Modal
          isOpen={open}
          onClose={() => setOpen(false)}
          title={
            <FormattedMessage
              defaultMessage="{venueName} opening hours"
              id="DM+Pvq"
              values={{ venueName: venue.name }}
            />
          }
        >
          <div className="-m-4 p-4">
            {Object.entries(WEEKDAYS).map(([key, day]) => (
              <div key={key} className="flex px-4 py-3 odd:bg-surface-bright">
                <div className="flex-1">{day}</div>
                <div className="flex-1 text-right">
                  {venue.hours &&
                    (venue.hours[key as keyof typeof WEEKDAYS] || []).map(
                      (hours: TimeSlot, index: number) => (
                        <div key={index}>
                          {convert24To12(hours.startTime)} –{' '}
                          {convert24To12(hours.endTime)}
                        </div>
                      ),
                    )}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    )
  } else {
    return null
  }
}
